import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/Auth-services/auth.service';
import { ErrorHandlerService } from 'src/app/shared/error-handling-service/error-handler.service';


const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;


export function passwordMatchValidator(controlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.root.get('password');
    const confirmPassword = control.root.get(controlName);

    if (password && confirmPassword && password.value !== confirmPassword.value) {
      return { passwordMismatch: true };
    } else {
      return null;
    }
  };
}


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  passForm!: FormGroup;
  submitted!: boolean;
  user_Data: any;
  constructor(
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private router: Router,
    private authService:AuthService,
    private errorHandler:ErrorHandlerService
  ) { }

  ngOnInit(): void {
    let userObj:any = localStorage.getItem('user_Data')
    this.user_Data=JSON.parse(userObj);

    this.dialogRef.backdropClick().subscribe(_=> {
      this.dialogRef.close();
    });
    this.passForm = new FormGroup({
      password: new FormControl('', [Validators.required,Validators.pattern(passwordRegex)]),
      passwordConfirm: new FormControl('', [Validators.required, passwordMatchValidator('passwordConfirm')])
    });
  }
  onSubmit() {
    this.submitted = true;
    if(this.passForm.invalid) {
      return
    };
    let passValues = {
      ...this.passForm.value,
      username:this.user_Data.email
    }
    this.authService.changePassword(passValues)
    .subscribe((res:any) => {
      this.submitted = false;
      if(res.body.status === 'success') {
        this.errorHandler.showSuccessMessage(res.body.message);
        this.router.navigate(['home'])
        this.dialogRef.close();

      } else {
        this.errorHandler.showError(Array(res.body.message));
      }
      
    }, (error) => {
      this.errorHandler.showError(
        Array("Sorry !! Something Went wrong Try again!")
      );
    })
  }
  cancel() {
    this.dialogRef.close()
  }

}
