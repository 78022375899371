<div id="chart">
    <apx-chart
      [series]="chartOptions?.series"
      [chart]="chartOptions?.chart"
      [dataLabels]="chartOptions?.dataLabels"
      [plotOptions]="chartOptions?.plotOptions"
      [responsive]="chartOptions?.responsive"
      [xaxis]="chartOptions?.xaxis"
      [legend]="chartOptions?.legend"
      [fill]="chartOptions?.fill"
      [colors]="chartOptions?.colors"
    ></apx-chart>
  </div>