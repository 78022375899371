<div class="right-contents">
    <div *ngIf="isLoading" class="spinnerloader">
      <mat-spinner style="top: 20%; left: 43%"></mat-spinner>
</div>

<div class="popup-header-section">
    <h1 class="head-popup">{{title}}</h1>
</div>
<!-- <hr> -->
<div class="popup-content">
    <form [formGroup]="userForm">
        <div class="row">
            <div class="col-md-6">
                <div class="input-label">First Name<span style="color: red;">*</span></div>
                <input type="text" class="form-input" formControlName="firstName" [ngStyle]="{'border-color': this.submitted && userForm.get('firstName')?.invalid ? 'red' : '#ccc'}">
                <div style="color:red;font-size: 12px;" *ngIf="this.submitted && userForm.get('firstName')?.errors?.['required']">*First name is missing.</div>
            </div>
            <div class="col-md-6">
                <div class="input-label">Last Name<span style="color: red;">*</span></div>
                <input type="text" class="form-input" formControlName="lastName" [ngStyle]="{'border-color': this.submitted && userForm.get('lastName')?.invalid ? 'red' : '#ccc'}">
                <div style="color:red;font-size: 12px;" *ngIf="this.submitted && userForm.get('lastName')?.errors?.['required']">*Last name is missing.</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-label">Username<span style="color: red;">*</span></div>
                <input type="text" class="form-input" formControlName="username" maxlength="8" [ngStyle]="{'border-color': this.submitted && userForm.get('username')?.invalid ? 'red' : '#ccc'}">
                <div style="color:red;font-size: 12px;" *ngIf="this.submitted && userForm.get('username')?.errors?.['minlength']">*Minimum 3 characters required.</div>
                <div style="color:red;font-size: 12px;" *ngIf="this.submitted && userForm.get('username')?.errors?.['required']">*Username is missing.</div>
            </div>
            <div class="col-md-6">
                <div class="input-label">Email<span style="color: red;">*</span></div>
                <input type="text" class="form-input" formControlName="email" [ngStyle]="{'border-color': this.submitted && userForm.get('email')?.invalid ? 'red' : '#ccc'}">
                <div style="color:red;font-size: 12px;" *ngIf="this.submitted && userForm.get('email')?.errors?.['required']">*Email is missing.</div>
                <div style="color:red;font-size: 12px;" *ngIf="this.submitted && userForm.get('email')?.errors?.['pattern']">*Invalid email.</div>
            </div>
        
        </div>
        <div class="row">
            <div class="col-md-6">
                    <div class="input-label">Products</div>
                    <ng-select [items]="products"
                    bindLabel="name"
                    bindValue="product_id"
                    [multiple]="true"
                    [selectableGroup]="true"
                    (change)="onMaterialGroupChange($event)"
                    formControlName="products"
                    class="custom">
                    </ng-select>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-3">
                        <div class="input-label">Code</div>
                        <input type="text" class="form-input" style="margin-top: .5px;" formControlName="phoneCode" [ngStyle]="{'border-color': this.submitted && userForm.get('phoneCode')?.invalid ? 'red' : '#ccc'}">
                    </div>
                    <div class="col-md-9">
                        <div class="input-label">Phone</div>
                        <input type="text" class="form-input phone-input" name="number" formControlName="phoneNumber"  maxlength="14" minlength="0" placeholder="(xxx) xxx-xxxx" mask="(000) 000-0000" [ngStyle]="{'border-color': this.submitted && userForm.get('phoneNumber')?.invalid ? 'red' : '#ccc'}">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <ng-container >
                <div class="col-md-6" style="margin-top: -14px;">
                    <div class="input-label">Password<span style="color: red;">*</span></div>
                    <input type="password" class="form-input" formControlName="password" maxlength="16" id="password-field" [ngStyle]="{'border-color': this.submitted && userForm.get('password')?.invalid ? 'red' : '#ccc'}" autocomplete="new-password">
                    <img src="../../../assets/images/dashboard/eye.svg" class="eye-icon" alt="" (click)="togglePassword()">
                    <div style="color:red;font-size: 12px;margin-top: -22px;" *ngIf="this.submitted && userForm.get('password')?.errors?.['required']">*Password is missing.</div>
                    <div style="color:red;font-size: 12px;margin-top: -19px;" *ngIf="this.submitted && userForm.get('password')?.errors?.['pattern']">*Password must be at least 8 characters long and contain 1 character, 1 capital letter, 1 special character, and 1 number.</div>
                </div>
                <!-- <div class="col-md-6" style="margin-top: -14px;">
                    <div class="input-label">Status</div>
                    <select  class="form-input select-input" formControlName="status" [ngStyle]="{'border-color': this.submitted && userForm.get('status')?.invalid ? 'red' : '#ccc'}">
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                     </select>
                </div> -->
            </ng-container>
          
            
        </div>
    </form>
    
    <div class="row">
        <div class="col-md-12">
            <div class="buttons-section">
                <button class="cancel-btn" (click)="cancel()">Cancel</button>
                <button class="save-btn" (click)="onSubmit()">{{clientUserDetails?.id ? 'Update' : 'Save'}}</button>
            </div>
        </div>
    </div>
</div>


