<div class="popup-header-section">
  <h1 class="head-popup">Confirm</h1>
</div>
<!-- <hr> -->
<div class="popup-content" >
  <h3>{{this.data['content']}}</h3>

<!-- <hr> -->
  <div style="text-align: center !important;margin-top: 43px;">
    <button type="button" class="cancel-btn" (click)="cancel()">Cancel</button>
    <button type="button" class="save-btn" (click)="confirm('confirm')" >Confirm</button>
  </div>
</div>

