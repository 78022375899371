import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handling-service/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  backendUrl = environment.backendUrl;
  clientUrl = this.backendUrl + 'clients/'
  userUrl = this.backendUrl + 'user/'
  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  AddorUpdateClient(inputValues:any): Observable < any > {  
    const formData = new FormData();
    for (const key in inputValues) {
      if (inputValues.hasOwnProperty(key)) {
        if (key === 'photo' && inputValues[key]) {
          formData.append(key, inputValues[key], inputValues[key].name);
        } else {
          formData.append(key, inputValues[key]);
        }
      }
    }
    // const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.post(this.clientUrl+ "addOrUpdateClient", formData, {observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  createNewUser(inputValues:any): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.post(this.userUrl+ "addUser", inputValues, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  updateClientUser(inputValues:any): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.patch(this.userUrl+ "updateUser", inputValues, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getAllSubscribedProducts(client_id:number): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.clientUrl+ "getAllSubProducts/" + client_id, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  createSubscription(values:any): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.post(this.clientUrl+ "addSubscription" ,values, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getAllClients(data:any): Observable < any > {
    let params;
    if(data && Object.keys(data).length) {
      params = new HttpParams({ fromObject: data });
    }
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.clientUrl+ "getAllClients" , {params,headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getClientDetails(client_id:number): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.clientUrl+ "getOneClient/" + client_id, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getAllClientUsers(client_id:number): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.clientUrl+ "getAllUsersUnderClient/" + client_id, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  deleteSubscription(subId:number): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.delete(this.clientUrl+ "deleteSubscription/" + subId, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  deleteClientUser(userId:number): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.delete(this.userUrl+ "deleteUser/" + userId, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  deleteClient(clientId:number): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.delete(this.clientUrl+ "deleteClient/" + clientId, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getAllClientsRunningSchedule(): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.clientUrl+ "getAllClientsRunningSchedule", { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getAllPracticeNamesRunningSchedule(): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.clientUrl+ "getAllPracticeRunningSchedule", { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getAllClientsWithUniqueId(): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.clientUrl+ "getAllPracticeWithClientId", { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getAllClientCities(data:any): Observable < any > {
    let params;
    if(data && Object.keys(data).length) {
      params = new HttpParams({ fromObject: data });
    }
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.clientUrl+ "getAllCitiesOfClients", {params, headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getAllClientStates(): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.clientUrl+ "getAllStatesOfClients", { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }



}
