
<div class="header">
    <!-- <div class="overflow-hidden" style="padding-bottom: 1%;">
        <div class="row">
            <div class="col-sm-6">
                
            </div>
            <div class="col-sm-6">
                <div class="top-bar">
                    <div class="navs">
                        <div class="search-container">
                            <div style="margin-right: 15px;"><img src="../../../../../assets/images/dashboard/info.svg" class="info-icon" alt=""></div> 
                            <div style="margin-right: 3px;"><img src="../../../../../assets/images/dashboard/Avatar.svg" class="avatar-icon" (click)="onClickSignout()" alt=""></div>
                          </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

<div class="body-container">
<app-sidebar></app-sidebar>
<div class="right-data" id="right-data">
    <router-outlet></router-outlet>
</div>
</div>