<div class="right-contents">
    <div *ngIf="isLoading" class="spinnerloader">
      <mat-spinner style="top: 35%; left: 28%"></mat-spinner>
</div>
<div class="outer-container container">
    <div class="">
      <div >
        <div class="row">
            <div class="col-sm-6">
              <!-- <div><img src="../../../../../../../assets/images/dashboard/Misc.svg" alt=""></div> -->
            </div>
            <div class="col-sm-6">
                <div class="top-bar">
                    <div class="navs"  [style.width.px]="calculateWidth(user_Data?.practice_name)">
                       <span class="header-practice-text" title="{{user_Data?.practice_name}}">Practice : {{user_Data?.practice_name}}</span>
                      <div style="margin-right: 3px;" *ngIf="userImg" ><img src="{{imgBaseUrl}}{{userImg}}" class="avatar-icon profile-img" (click)="onClickSignout()" alt=""></div>
                      <div style="margin-right: 3px;" *ngIf="!userImg"><img src="../../../../../assets/images/dashboard/Avatar.svg" class="avatar-icon" (click)="onClickSignout()" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <section class="cards">
        <div class="car-heading">
            <span style="font-size: 18px;opacity:.6">{{startDate}} - {{endDate}}</span>
        </div>
        <div class="cards-container">
              <div class="single-card">
                  <div class="card-title">Scheduled Batches</div>
                  <div class="count-percent-combo">
                      <div class="count">{{statistics?.totalSchedules}}</div>
                  </div>
              </div>
              <div class="single-card even-card">
                  <div class="card-title">Completed Batches</div>
                  <div class="count-percent-combo">
                      <div class="count">{{statistics?.totalCompletedSchedules}}</div>
                  </div>
              </div>
                 <div class="single-card">
                  <div class="card-title">Processed Amount</div>
                  <div class="count-percent-combo">
                      <div class="count">${{statistics?.totalProcessedAmount}}</div>
                  </div>
              </div>
              <div class="single-card even-card">
                  <div class="card-title">Processed Transactions</div>
                  <div class="count-percent-combo">
                      <div class="count">{{statistics?.totalProcessedTransactions}}</div>
                  </div>
              </div>
              <div class="single-card">
                <div class="card-title">Posted Amount</div>
                <div class="count-percent-combo">
                    <div class="count">${{statistics?.totalPostedAmount}}</div>
                </div>
            </div>
            <div class="single-card even-card">
                <div class="card-title">Posted Transactions</div>
                <div class="count-percent-combo">
                    <div class="count">{{statistics?.totalPostedTransactions}}</div>
                </div>
            </div>
        </div>
      </section>
      <section class="graphs">
        <div class="row">
            <div class="col-md-6" *ngIf="schedulerBarChartData">
                <div class="graph-card">
                    <div class="header-row">
                        <div class="header-container">
                            <div class="table-header">Schedules <span style="font-size: 14px;opacity:.6">({{startDate}} - {{endDate}})</span></div>
                        </div>
                    </div>
                    <div>
                        <app-bar-graph [inputData]="schedulerBarChartData"></app-bar-graph>
                    </div>
                    
                </div>
            </div>
            <div class="col-md-6" *ngIf="lastFiveSchedules">
                <div class="graph-card" style="height: 359px;">
                    <div class="header-row">
                        <div class="header-container">
                            <div class="table-header">Last 5 Schedules</div>
                        </div>
                    </div>
                    <app-scheduler-summary [inputData]="lastFiveSchedules"></app-scheduler-summary>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 2%;">
            <!-- <div class="col-md-6" *ngIf="payorPieGraphData">
                <div class="graph-card">
                    <div class="header-row">
                        <div class="header-container">
                            <div class="table-header">Payors</div>
                        </div>
                    </div>
                    <app-pie-chart [inputData]="payorPieGraphData"></app-pie-chart>
                </div>
            </div> -->
            <div class="col-md-6" *ngIf="dateWiseSchedule">
                <div class="graph-card">
                    <div class="header-row">
                        <div class="header-container">
                            <div class="table-header">Schedules Last Week</div>
                        </div>
                    </div>
                    <app-line-chart [inputData]="dateWiseSchedule"></app-line-chart>
                </div>
            </div>
        </div>
      </section>
    </div>
  </div>