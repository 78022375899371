import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import {
  ChartComponent,
} from "ng-apexcharts";

export type ChartOptions = {
  series: any;
  chart: any;
  dataLabels: any;
  plotOptions: any;
  responsive: any;
  xaxis: any;
  legend: any;
  fill: any;
  colors:any;
};

@Component({
  selector: 'app-stacked-column-graph',
  templateUrl: './stacked-column-graph.component.html',
  styleUrls: ['./stacked-column-graph.component.css']
})
export class StackedColumnGraphComponent implements OnInit,OnChanges {
  @Input() inputData: any;
  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;

  constructor() {
    
  }

  ngOnInit(): void {
    this.displayGraph();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputData']) {
      this.displayGraph();
    }
  }

  displayGraph() {
    let lables =  this.inputData.labels;
    this.chartOptions = {
      series: [
        ...this.generateSeriesObjs(this.inputData)
      ],
      chart: {
        type: "bar",
        height: 240,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      colors: [
        "#6EC531",
        "#ff0000"
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        type: "category",
        categories: [
         ...lables
        ]
      },
      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    };
  }

  generateSeriesObjs(inputData:any) {
    let series:any = [];
    for(let el in inputData) {
      if(el !== 'labels') {
        series.push({
          name:el,
          data: inputData[el]
        })
      }
    } 
    return series;
    
  }

}
