import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthService } from 'src/app/shared/Auth-services/auth.service';
declare var $:any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  showInnerNav = false;
  expanded:boolean = true;
  user_Data: any;
  showCashport!:boolean;
  constructor(private router: Router, private route: ActivatedRoute,private auth:AuthService) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
  ).subscribe(() => {
    console.log('checking..');
    
      this.checkInnerNav();
  });
   }

  ngOnInit(): void {
    let userObj:any = localStorage.getItem('user_Data')
    this.user_Data=JSON.parse(userObj);
    let subProduct = this.user_Data.subscribed_products[0];
    if(this.user_Data.user_role === 2 && subProduct.client_subscription_status === 'active' &&
    subProduct.user_permission === 'active') {
      this.showCashport = true;
    } else if(this.user_Data.user_role === 1) {
      this.showCashport = true;
    } else {
      this.showCashport = false;
    }

  }
  toggleInnerNav() {
    this.showInnerNav = !this.showInnerNav;
  }
  checkInnerNav() {
    // Extract the current URL segment
    const currentUrl = this.router.url;
    
    // Check if the current URL matches any of the inner-nav links
    if (currentUrl.includes('cash-port/practice-management') ||
        currentUrl.includes('cash-port/insurance-payors') ||
        currentUrl.includes('cash-port/scheduler-management')) {
        this.showInnerNav = true;
        return true;
    } else {
        this.showInnerNav = false;
        return false;
    }
}
checkCashPort() {
  const currentUrl = this.router.url;
  if (currentUrl.includes('cash-port/practice-management') ||
      currentUrl.includes('cash-port/insurance-payors') ||
      currentUrl.includes('cash-port/scheduler-management')) {
      return true;
  } else {
      return false;
  }
}
onClickExpand() {
   this.expanded = !this.expanded
   if(!this.expanded) {
    (document.getElementById('navbar') as HTMLElement).style.display = 'none';
    (document.getElementById('logo-combo') as HTMLElement).style.display = 'none';
    (document.getElementById('logo-icon') as HTMLElement).style.display = 'block';
    $('.right-data').css('margin-left', 90);
    $('.spinnerloader').css('padding-left', 290);
   } else {
    (document.getElementById('navbar') as HTMLElement).style.display = 'block';
    (document.getElementById('logo-combo') as HTMLElement).style.display = 'block';
    (document.getElementById('logo-icon') as HTMLElement).style.display = 'none';
    $('.right-data').css('margin-left', 280);
    $('.spinnerloader').css('padding-left', 0);
   }
}

}
