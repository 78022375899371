import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebsiteComponent } from './website/website.component';
import { LoginComponent } from './Auth/login/login.component';
import { SignupComponent } from './Auth/signup/signup.component';
import { SidebarComponent } from './Smile-AI/sidebar/sidebar.component';
import { ForgotPasswordComponent } from './Auth/forgot-password/forgot-password.component';
import { HeaderComponent } from './Smile-AI/header/header.component';
import { DashboardComponent } from './shared/common-modules/dashboard/dashboard.component';
import { AuthGuard } from './shared/Auth-services/auth.guard';
import { ClientProfileComponent } from './Smile-AI/content/users/client-profile/client-profile.component';


const routes: Routes = [
  { path: '', component: WebsiteComponent },
  { path: 'website', component: WebsiteComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'home', component: HeaderComponent,canActivate:[AuthGuard],data: {breadcrumb: { skip: true }},
  children: [
    {path:'',redirectTo:'dashboard', pathMatch: 'full'},
    { path: 'dashboard', component: DashboardComponent },
    { path: 'client-profile', component: ClientProfileComponent },
    { path: 'clients', loadChildren: () => import('./Smile-AI/content/admin/client-management/client-management.module').then(m => m.ClientManagementModule) },
    { path: 'cash-port', loadChildren: () => import('./shared/common-modules/cash-port/cash-port.module').then(m => m.CashPortModule) },
    { path: 'user-management', loadChildren: () => import('./Smile-AI/content/admin/user-management/user-management.module').then(m => m.UserManagementModule) }
  ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
