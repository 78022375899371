import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Carousel } from "bootstrap";
import { WebsiteService } from '../shared/functionality-services/website.service';
import { ErrorHandlerService } from '../shared/error-handling-service/error-handler.service';
import { LoaderService } from '../shared/loader-service/loader.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.css']
})
export class WebsiteComponent implements OnInit,AfterViewInit {
  @ViewChild("carouselExampleSlidesOnly") carouselElement!: ElementRef<HTMLElement>;
  submitted:boolean = false;
  contactForm!: FormGroup;

  carouselRef!: Carousel;
  slideIndex = 0;
  constructor(
    private websiteService:WebsiteService,
    private errorHandler:ErrorHandlerService,
    private loaderService:LoaderService,
    private router: Router, private renderer: Renderer2, private elementRef: ElementRef

  ) { 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const fragment = this.router.parseUrl(this.router.url).fragment;
        if (fragment) {
          const element = this.elementRef.nativeElement.querySelector(`#${fragment}`);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    });
  }
 



  ngAfterViewInit(): void {

    this.carouselRef = new Carousel(this.carouselElement.nativeElement, {
      interval: 3000,
    });
  }

  ngOnInit(): void {
    setTimeout(()=> {
      this.carouselRef.cycle();
    },1000);
    this.contactForm = new FormGroup({
      email: new FormControl('', [Validators.required,Validators.email]),
      phone: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      name: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z ]*$')]),
      practiceName: new FormControl(''),
      practiceManagementSystem: new FormControl(''),
      practiceLocation: new FormControl(''),
    });
    // this.showSlide(this.slideIndex);
    // setInterval(() => {
    //   this.showSlide(++this.slideIndex);
    // },3000)
  }
  showSlide(n:any) {
    const slides:any = document.querySelectorAll('.slider');
    if (n >= slides.length) {
      this.slideIndex = 0;
    }
    if (n < 0) {
      this.slideIndex = slides.length - 1;
    }
    slides.forEach((slide:any) => slide.style.display = 'none');
    slides[this.slideIndex].style.display = 'flex';
  }
  nextSlide() {
    this.carouselRef.next();
    // this.showSlide(++this.slideIndex);
    // console.log(this.slideIndex)
  }
  prevSlide() {
    this.carouselRef.prev();
    // this.showSlide(--this.slideIndex);
  }
  onSubmit() {
    this.submitted = true;
    if(!this.contactForm.valid) {
      return;
    } else {
      this.loaderService.isLoading.next(true);
      let values = this.contactForm.value;
      this.websiteService.contact(values)
      .subscribe(
        (res) => {
          this.submitted = false;
          this.loaderService.isLoading.next(true);
          if(res.body.status === 'success') {          
            this.errorHandler.showSuccessMessage(res.body.message)
          } else {
            this.errorHandler.showError(res.body.message);
          }
          
        }
      )
    }
  }
}
