import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WebsiteComponent } from './website/website.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './Auth/login/login.component';
import { SignupComponent } from './Auth/signup/signup.component';
import { IntalledPacksModule } from './shared/installed-packs-module/intalled-packs/intalled-packs.module'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptorService } from './shared/loader-service/loader.interceptor.service';
import { AuthInterceptorService } from './shared/Auth-services/auth.interceptor.service';
import { HttpClientModule } from '@angular/common/http'; 
import { AuthGuard } from './shared/Auth-services/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxSpinnerModule } from "ngx-spinner";
import { ForgotPasswordComponent } from './Auth/forgot-password/forgot-password.component';
import { SidebarComponent } from './Smile-AI/sidebar/sidebar.component';
import { HeaderComponent } from './Smile-AI/header/header.component';
import { DashboardComponent } from './shared/common-modules/dashboard/dashboard.component';
import { ConfirmPopupComponent } from './shared/common-components/confirm-popup/confirm-popup.component';
import { SignoutPopupComponent } from './Smile-AI/signout-popup/signout-popup.component';
import { ChangePasswordComponent } from './Smile-AI/signout-popup/change-password/change-password.component';
import { ClientProfileComponent } from './Smile-AI/content/users/client-profile/client-profile.component';
import { AdminDashboardComponent } from './shared/common-modules/dashboard/admin-dashboard/admin-dashboard.component';
import { ClientDashboardComponent } from './shared/common-modules/dashboard/client-dashboard/client-dashboard.component';
import { DonutGraphComponent } from './shared/common-components/donut-graph/donut-graph.component';
import { BarGraphComponent } from './shared/common-components/bar-graph/bar-graph.component';
import { PieChartComponent } from './shared/common-components/pie-chart/pie-chart.component';
import { LineChartComponent } from './shared/common-components/line-chart/line-chart.component';
import { SchedulerSummaryComponent } from './shared/common-modules/dashboard/client-dashboard/scheduler-summary/scheduler-summary.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { StackedColumnGraphComponent } from './shared/common-components/stacked-column-graph/stacked-column-graph.component';



const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'MM/DD/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'MM/DD/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export const options: Partial<IConfig> = {
  thousandSeparator: "'"
};

@NgModule({
  declarations: [
    AppComponent,
    WebsiteComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    SidebarComponent,
    HeaderComponent,
    DashboardComponent,
    ConfirmPopupComponent,
    SignoutPopupComponent,
    ChangePasswordComponent,
    ClientProfileComponent,
    AdminDashboardComponent,
    ClientDashboardComponent,
    DonutGraphComponent,
    BarGraphComponent,
    PieChartComponent,
    LineChartComponent,
    SchedulerSummaryComponent,
    StackedColumnGraphComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    IntalledPacksModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxSpinnerModule
  ],
  providers: [
    LoadingInterceptorService,AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptorService,
      multi: true
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
