<div class="sidebar" [class.sidebar-closed]="!expanded">
   <div class="logo-section align-items-center">
    <img loading="lazy" id="logo-combo" src="../../../assets/images/dashboard/smileai-blue.svg" class="logo-icon" />
    <img loading="lazy" id="logo-icon" src="../../../assets/images/dashboard/logo-icon.svg"  style="display: none;width: 50px;" />
   </div>
   <hr style="color: #0866FF;">
   <div class="expand-section" (click)="onClickExpand()"> <img src="../../../assets/images/dashboard/expand-arrow.svg" class="expand-arrow" alt=""></div>
   <ul class="nav-items" id="navbar">
    <li class="nav-item" [routerLink]="['dashboard']"  routerLinkActive="active"><a class="nav-link" title="Dashbaord">Dashboard</a></li>
    <li class="nav-item " *ngIf="user_Data?.user_role === 1" [routerLink]="['user-management']"  routerLinkActive="active"><a class="nav-link" title="User Management" >User Management</a></li>
    <li class="nav-item " *ngIf="user_Data?.user_role === 1" [routerLink]="['clients']"  routerLinkActive="active"><a class="nav-link" title="Practice Summary" >Practice Summary</a></li>
    <li class="nav-item " *ngIf="user_Data?.user_role === 2" [routerLink]="['client-profile']"  routerLinkActive="active"><a class="nav-link" title="Profile" >Profile</a></li>
    <li class="double-layered-nav" [class.background-yellow]="showInnerNav || checkCashPort()" *ngIf="showCashport">
        <div class="nav-item cashport-nav" (click)="toggleInnerNav()" [class.active-nav]="showInnerNav || checkCashPort()">Cashport Management  
            <span><img *ngIf="!showInnerNav"  src="../../../assets/images/dashboard/arrow_drop_down.svg" alt=""></span>
           <span> <img *ngIf="showInnerNav"  src="../../../assets/images/dashboard/arrow_drop_up.svg" alt=""></span>
        </div>
       <div class="inner-nav-section">
        <ul class="inner-nav-items" [class.show-inner-nav]="showInnerNav">
            <li class="inner-nav-item" [routerLink]="['cash-port/practice-management']" [routerLinkActiveOptions]="{ exact: false }" routerLinkActive="active" title="PM Softwares">PM Softwares</li>
            <li class="inner-nav-item" [routerLink]="['cash-port/insurance-payors']" [routerLinkActiveOptions]="{ exact: false }"  routerLinkActive="active" title="Insurance Payors">Insurance Payors</li>
            <li class="inner-nav-item" [routerLink]="['cash-port/scheduler-management']"  [routerLinkActiveOptions]="{ exact: false }" routerLinkActive="active" title="Scheduler Management">Scheduler Management</li>
        </ul>
       </div>
    </li>
   </ul>
  </div>
