
<div>
    <div id="chart">
        <apx-chart
          [series]="chartOptions?.series"
          [chart]="chartOptions?.chart"
          [labels]="chartOptions?.labels"
          [fill]="chartOptions?.fill"
          [dataLabels]="chartOptions?.dataLabels"
          [responsive]="chartOptions?.responsive"
          [colors]="chartOptions?.colors"
        ></apx-chart>
      </div>
</div>
