<div id="chart">
    <apx-chart
      [series]="chartOptions?.series"
      [responsive]="chartOptions.responsive"
      [chart]="chartOptions?.chart"
      [xaxis]="chartOptions?.xaxis"
      [dataLabels]="chartOptions?.dataLabels"
      [grid]="chartOptions?.grid"
      [stroke]="chartOptions?.stroke"
      [title]="chartOptions?.title"
      
    ></apx-chart>
  </div>
