import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handling-service/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {
  backendUrl = environment.backendUrl;
  websiteUrl = this.backendUrl + 'website/'
  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  contact(inputValues:any): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.post(this.websiteUrl+ "contactUs", inputValues, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
}
