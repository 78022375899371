
<div class="popup-header-section">
    <h1 class="head-popup">Change Password</h1>
</div>
<!-- <hr> -->
<div class="popup-content">
    <form [formGroup]="passForm">
        <div class="row form-row">
            <div class="col-md-4">
                <div class="input-label ">Password <span style="color: red;">*</span></div>
            </div>
            <div class="col-md-8">
                <input type="password" class="form-input" [maxLength]="12" formControlName="password" [ngStyle]="{'border-color': this.submitted && passForm.get('password')?.invalid ? 'red' : '#ccc'}">
                <div style="color:red;font-size: 12px;" *ngIf="this.submitted && passForm.get('password')?.errors?.['required']">*Password is missing.</div>
                <div style="color:red;font-size: 12px;" *ngIf="this.submitted && passForm.get('password')?.errors?.['pattern']">*Password must be at least 8 characters long and contain 1 character, 1 capital letter, 1 special character, and 1 number.</div>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-md-4">
                <div class="input-label">Repeat Password <span style="color: red;">*</span></div>
            </div>
            <div class="col-md-8">
                <input type="password" class="form-input" [maxLength]="12" formControlName="passwordConfirm" [ngStyle]="{'border-color': this.submitted && passForm.get('passwordConfirm')?.invalid ? 'red' : '#ccc'}">
                <div style="color:red;font-size: 12px;" *ngIf="this.submitted && passForm.get('passwordConfirm')?.errors?.['required']">*Confirm password is missing.</div>
                <div style="color:red;font-size: 12px;" *ngIf="this.submitted && !passForm.get('passwordConfirm')?.errors?.['required'] &&  passForm.get('passwordConfirm')?.errors?.['passwordMismatch']">*Password is not matching.</div>
            </div>
        </div>
    </form>
    
    <div class="row">
        <div class="col-md-12">
            <div class="buttons-section">
                <button class="cancel-btn" (click)="cancel()">Cancel</button>
                <button class="save-btn" (click)="onSubmit()">Save</button>
            </div>
        </div>
    </div>
</div>
