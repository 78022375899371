import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddUserPopupComponent } from '../../admin/client-management/client-summary/add-client/add-user-popup/add-user-popup.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientService } from 'src/app/shared/functionality-services/client.service';
import { ErrorHandlerService } from 'src/app/shared/error-handling-service/error-handler.service';
import { HelperService } from 'src/app/shared/functionality-services/helper.service';
import { ConfirmPopupComponent } from 'src/app/shared/common-components/confirm-popup/confirm-popup.component';
import { SignoutPopupComponent } from 'src/app/Smile-AI/signout-popup/signout-popup.component';
import { environment } from 'src/environments/environment';

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.css']
})
export class ClientProfileComponent implements OnInit {
  clientId!: any;
  dataSource: any
  subDataSource: any;
  userDataSource: any;
  isUserTab: boolean = false;
  isSubscriptionTab: boolean = false;
  clientForm!: FormGroup;
  subscriptionForm!: FormGroup;
  clientFormSubmitted: boolean = false;
  subscriptionFormSubmitted: boolean = false;
  allProducts: any[] = [];
  usersColumnsToDisplay = ['first_name', 'last_name','username', 'email','phone_number','subscribed_products', 'user_created_at', 'user_updated_at', 'actions'];
  subColumnsToDisplay = ['name', 'start_date', 'end_date','fees', 'status']
  @ViewChild('subSort') subSort!: MatSort;
  @ViewChild('subPaginator') subPaginator!: MatPaginator;

  @ViewChild('userSort') userSort!: MatSort;
  @ViewChild('userPaginator') userPaginator!: MatPaginator;
  isLoading!: boolean;
  countries!: any[];
  imgBaseUrl = environment.baseUrl;
  subscriptionEditID: any;
  clientUsers: any;
  clientSubscriptions: any;
  user_Data: any;
  states: any;
  isUsa: boolean = false;
  userImg: any;
  clients: any;
  selectedLogo: any;
  image!: any;
  cities!: any[];
  constructor(

    private dialog: MatDialog,
    private clientService: ClientService,
    private errorHandler: ErrorHandlerService,
    public helperService: HelperService,
  ) { }

  ngOnInit(): void {

    this.clientForm = new FormGroup({
      clientName: new FormControl('', [Validators.required]),
      clientAddress: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      practiceName: new FormControl('', [Validators.required]),
      practiceState: new FormControl('',[Validators.required]),
      practiceCity: new FormControl(''),
      practiceAddress: new FormControl(''),
      photo: new FormControl(''),
      parentId:new FormControl(''),
      status: new FormControl('active'),
      country: new FormControl('United States of America',[Validators.required]),
      clientFirstName: new FormControl('',[Validators.required]),
      clientLastName: new FormControl('',[Validators.required]),
      clientPhoneNumber: new FormControl('',[Validators.required]),
      clientEmail: new FormControl('',[Validators.pattern(emailRegex),Validators.required]),
      primaryFirstName: new FormControl('',[Validators.required]),
      primaryLastName: new FormControl('',[Validators.required]),
      primaryPhoneNumber: new FormControl('',[Validators.required]),
      primaryEmail: new FormControl('',[Validators.pattern(emailRegex),Validators.required]),
      communicationEmail: new FormControl('',[Validators.pattern(emailRegex)]),

    })
    this.clientForm.get('country')?.valueChanges.subscribe(newValue => {
      if(newValue === 'United States of America') {
        this.isUsa = true;
      } else {
        this.isUsa = false;
      }
    })
    this.clientForm.get('practiceState')?.valueChanges.subscribe(newValue => {
     if(this.isUsa) {
      this.getAllCitiesOfState(newValue);
     }
    })
    let userObj:any = localStorage.getItem('user_Data')
    this.user_Data=JSON.parse(userObj)
    this.clientId = this.user_Data.client_id;
    // this.helperService.profilePictureUrl$.subscribe(url => {
    //   this.userImg = url;
    // });
    this.userImg = this.user_Data.practice_logo
    if (this.clientId) {
      this.getClientDetails();
      this.getAllSubscribedProducts();
      this.getClientUsers();
    }
    this.getCountryList();
    this.getStateList();
    this.getAllClients(null);

  }

  getStateColor(status: string) {
    switch (status) {
      case ('active'):
        return 'green-color';
        break;

      case ('inactive'):
        return 'red-color';
        break;
      default:
        return ''
    }
  }
  getAllClients(data:any) {
    this.isLoading = true;
    this.clientService.getAllClients(data)
    .subscribe(
      (res:any) => {
        this.isLoading = false;
        let response = res.body;
        if(response.status === 'success') {
          this.clients = this.helperService.sortData(response.clients,'client_name');
        } else {
        }
      }
    )
  }
  getAllCitiesOfState(state:string) {
    this.isLoading = true;
    this.helperService.getAllCitiesOfState(state)
    .subscribe(
      (res:any) => {
        this.isLoading = false;
        let response = res.body;
        if(response.status === 'success') {
          this.cities = response.cities;
        } else {
          this.cities = [];
        }
      }
    )
  }

  AddNewUser(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'add-user-popup';
    dialogConfig.position = {
      top: '60px',
      right: '20px'
    };

    dialogConfig.data = {
      clientId: this.clientId,
      ...data
    }

    const dialogRef = this.dialog.open(AddUserPopupComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        this.getClientUsers();
      }
    )

  }
  onClickTab(tab: string) {
    if (tab === 'users') {
      this.isUserTab = true;
      this.isSubscriptionTab = false;
    } else if (tab === 'subscriptions') {
      this.isSubscriptionTab = true;
      this.isUserTab = false;
    } else {
      this.isUserTab = false;
      this.isSubscriptionTab = false
    }
  }
  onSubmitClientForm() {    
    this.isLoading = true
    this.clientFormSubmitted = true;
    if (this.clientForm.invalid) {
      this.isLoading = false
      return
    }
    let value = { ...this.clientForm.value };
      value.id = +this.clientId
      value.createdBy = this.user_Data.id;
      value.photo = this.selectedLogo;  
    this.isLoading = true
    
    this.clientService.AddorUpdateClient(value)
      .subscribe(
        (res: any) => {
          this.isLoading = false;
          let response = res.body;
          this.isLoading = false;
          if (response.status === 'success') {
            this.errorHandler.showSuccessMessage(response.message);
            if(response.logo) {
              this.helperService.updateProfilePictureUrl(response.logo);
            }
            
            this.getClientDetails();
          } else {
            this.errorHandler.showError(Array(response.message))
          }
        }, (error) => {
          this.errorHandler.showError(
            Array("Sorry !! Something Went wrong Try again!")
          );
          this.isLoading = false
        }
      )
  }
  getCountryList() {
    this.helperService.getCountryList()
      .subscribe(
        (res: any) => {
          let response = res.body;
          if (response.status === 'success') {
            this.countries = response.countries;
          } else {
            this.countries = [];
          }
        }, (error) => {
          this.errorHandler.showError(
            Array("Sorry !! Something Went wrong Try again!")
          );
          this.isLoading = false
        }
      )
  }
  getStateList() {
    this.helperService.getStateList()
      .subscribe(
        (res: any) => {
          let response = res.body;
          if (response.status === 'success') {
            this.states = response.states;
          } else {
            this.states = [];
          }
        }, (error) => {
          this.errorHandler.showError(
            Array("Sorry !! Something Went wrong Try again!")
          );
          this.isLoading = false
        }
      )
  }

  onChangedPage(event: any) { }
  onChangeLogo(event:any) {
    this.selectedLogo = event.target.files[0];
    
    // Check if an image is selected
    if (!this.selectedLogo) {
      return;
    }
  
    // Check if the image format is valid
    if (
      !['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(
        this.selectedLogo.type
      )
    ) {
      this.errorHandler.showError(['Invalid Format.']);
      this.selectedLogo = null;
      return;
    }
  
    // Check if the image size is within the limit (5 MB)
    const maxSizeInBytes = 3 * 1024 * 1024; // 5 MB in bytes
    if (this.selectedLogo.size > maxSizeInBytes) {
      this.errorHandler.showError(['Image size exceeds 3 MB limit.']);
      this.selectedLogo = null;
      return;
    }
  
    this.image = null;
  }

  getAllSubscribedProducts() {
    this.isLoading = true;
    this.clientService.getAllSubscribedProducts(this.clientId)
      .subscribe
      ((res: any) => {
        this.isLoading = false;
        if (res.body.status === 'success') {
          this.clientSubscriptions = res.body.subscriptions
          this.subDataSource = new MatTableDataSource(this.clientSubscriptions);
          this.subDataSource.sort = this.subSort;
          this.subDataSource.paginator = this.subPaginator;
        } else {
          this.subDataSource = new MatTableDataSource([]);
        }

      }, (error) => {
        this.errorHandler.showError(
          Array("Sorry !! Something Went wrong Try again!")
        );
        this.isLoading = false
      })
  }
  getClientDetails() {
    this.isLoading = true;
    this.clientService.getClientDetails(this.clientId)
      .subscribe(
        (res: any) => {
          this.isLoading = false;
          let response = res.body;
          if (response.status === 'success') {
            let client = response.client;
            this.image = client.practice_logo;
            this.clientForm.patchValue({
              clientName: client.client_name,
              clientAddress: client.client_address,
              zip: client.zip,
              practiceState: client.practice_state,
              status: client.status,
              country: client.country,
              practiceName:client.practice_name,
              parentId:client.parent_id,
              practiceAddress:client.practice_address,
              clientFirstName:client.client_first_name,
              clientLastName:client.client_last_name,
              clientPhoneNumber:client.client_phone_number,
              clientEmail:client.client_email,
              primaryFirstName: client.primary_first_name,
              primaryLastName:client.primary_last_name,
              primaryPhoneNumber:client.primary_phone_number,
              primaryEmail:client.primary_email,
              communicationEmail:client.communication_email
            });
            setTimeout(() => {
              this.clientForm.patchValue({
                practiceCity:client.practice_city,
              })
            },1000)
          }
        }
      )
  }
  getClientUsers() {
    this.isLoading = true;
    this.clientService.getAllClientUsers(this.clientId)
      .subscribe(
        (res: any) => {
          let response = res.body;
          this.isLoading = false;
          if (response.status === 'success') {
            this.clientUsers = response.users;
            this.userDataSource = new MatTableDataSource(this.clientUsers);
            this.userDataSource.sort = this.userSort;
            this.userDataSource.paginator = this.userPaginator;
          } else {
            this.errorHandler.showError(Array(response.message));
          }
        }, (error) => {
          this.errorHandler.showError(
            Array("Sorry !! Something Went wrong Try again!")
          );
          this.isLoading = false
        }
      )
  }

  onEditClientUser(user: any) {
    let data = {
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      username: user.username,
      password: '',
      phoneCode: user.phone_code,
      phoneNumber: user.phone_number,
      products: this.getSubscribedProductsId(user)
    }
    this.AddNewUser(data);
  }
  getSubscribedProductsId(user:any) {
    return user.subscribed_products.map((product:any) => product.product_id )
  }
  
  onDeleteClientUser(user:any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'confirm-popup';
    dialogConfig.position = {
      top: '60px',
      right: '20px'
    };
    dialogConfig.data = {
      content:'Are you sure you want to delete this item?'
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if(res) {
          this.isLoading = true;
          this.clientService.deleteClientUser(user.id)
          .subscribe(
            (res:any) => {
              this.isLoading = false;
              let response = res.body;
              if(response.status === 'success') {
                this.errorHandler.showSuccessMessage(response.message);
                this.getClientUsers();
              } else {
                this.errorHandler.showError(Array(response.message));
              }
            }, (error) => {
              this.errorHandler.showError(
                Array("Sorry !! Something Went wrong Try again!")
              );
              this.isLoading = false
            }
          )
        }
      }
    )
  }
  onClickSignout() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'signout-popup';
    dialogConfig.position = {
      top: '60px',
      right: '20px'
    };
    const dialogRef = this.dialog.open(SignoutPopupComponent, dialogConfig)
  }
  getProductName(name:string) {
    return name === 'cashPort' ? 'Cash Port' : ''
  }
  applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.userDataSource.filter = filterValue.trim().toLowerCase();
	}
  getImageNameFromUrl(url:string) {
    return url.split('/')[url.split('/').length-1]
  }
  getPhoneNumber(code:string,number:string) {
    return number ? code + ' ' + number : ''
  }
  calculateWidth(practiceName: string): number {
    // Calculate the width based on the length of the practice name
    // You can adjust the multiplier as needed based on your design
    const width = ((practiceName?.length || 0) * 10 ) + 140; // Adjust multiplier as needed
    return width;
}

}
