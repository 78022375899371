import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handling-service/error-handler.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  backendUrl = environment.backendUrl;
  authUrl = this.backendUrl + 'user/'
  userDAta!: Object | null;
  xToken: any;
  refToken: any;

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
    private router:Router,
    private dialogRef: MatDialog
  ) { }

  login(inputValues:any): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.post(this.authUrl+ "login", inputValues, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
     tap((resData:any) => {
      console.log(resData,'userDat');
      if(resData.body?.status === 'success') {
        this.userDAta = resData.body?.['userDetails'][0];
        this.xToken = resData.body?.['accessToken'];
        this.refToken = resData.body?.['refToken'];
        localStorage.setItem("userToken", this.xToken);
        localStorage.setItem('refToken',this.refToken);
        localStorage.setItem("user_Data", JSON.stringify(this.userDAta));
      }
      // console.log(this.userDAta)
    })
  );
  }
  generateOTP(inputValues:any): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.post(this.authUrl+ "generateOtp", inputValues, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
     tap((resData:any) => {})
  );
  }
  verifyOTP(inputValues:any): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.post(this.authUrl+ "verifyOtp", inputValues, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
     tap((resData:any) => {
      this.xToken = resData.body?.['accessToken'];
      localStorage.setItem("userToken", this.xToken);
     })
  );
  }
  changePassword(inputValues:any): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.post(this.authUrl+ "changePassword", inputValues, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
     tap((resData:any) => {
     })
  );
  }
  generateToken(): Observable < any > {
    let refUid = localStorage.getItem('refToken')
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.post(this.authUrl+ "sendNewToken", { refUid }, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
     tap((resData:any) => {
      if(resData.body?.status === 'success') {
        this.userDAta = resData.body?.['userDetails'][0];
        this.xToken = resData.body?.['accessToken'];
        this.refToken = resData.body?.['refToken'];
        localStorage.setItem("userToken", this.xToken);
        localStorage.setItem('refToken',this.refToken);
        localStorage.setItem("user_Data", JSON.stringify(this.userDAta));
      }
     })
  );
  }
  
  logout() {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userData');
    this.dialogRef.closeAll();
    this.router.navigate(['/login']);
  }
}