import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handling-service/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  backendUrl = environment.backendUrl;
  dashboardUrl = this.backendUrl + 'dashboard/'
  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  getDashboardStatisticsForAdmin(data:any): Observable < any > {
    let params;
    if(data && Object.keys(data).length) {
      params = new HttpParams({ fromObject: data });
    }
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.dashboardUrl+ "dashboardStatisticsAdmin", {params,headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getDashboardStatisticsForClient(client_id:any,data:any): Observable < any > {
    let params;
    if(data && Object.keys(data).length) {
      params = new HttpParams({ fromObject: data });
    }
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.dashboardUrl+ "dashboardStatisticsClient/" + client_id, {params, headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getClientStatusCountForAdmin(data:any) {
    let params;
    if(data && Object.keys(data).length) {
      params = new HttpParams({ fromObject: data });
    }
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.dashboardUrl+ "clientsInactiveAndActiveListAdmin", {params, headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
}

