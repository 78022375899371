<div class="right-contents">
    <div *ngIf="isLoading" class="spinnerloader">
      <mat-spinner style="top: 35%; left: 28%"></mat-spinner>
</div>
<div class="outer-container">
    <div >
        <div class="row">
            <div class="col-sm-6">
                
            </div>
            <div class="col-sm-6">
                <div class="top-bar">
                    <div class="navs"  [style.width.px]="calculateWidth(user_Data?.practice_name)">
                        <span class="header-practice-text" title="{{user_Data?.practice_name}}">Practice : {{user_Data?.practice_name}}</span>
                      <div style="margin-right: 3px;" *ngIf="userImg" ><img src="{{imgBaseUrl}}{{userImg}}" class="avatar-icon profile-img" (click)="onClickSignout()" alt=""></div>
                      <div style="margin-right: 3px;" *ngIf="!userImg"><img src="../../../../../assets/images/dashboard/Avatar.svg" class="avatar-icon" (click)="onClickSignout()" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="table-section">
        <div class="table-container">
            <div class="header-row">
                <div class="header-container">
                    <!-- <div class="table-header">Add Client</div> -->
                    <ul class="nav nav-tabs justify-content-center" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active " (click)="onClickTab('client')" id="client-tab" data-bs-toggle="tab" data-bs-target="#clients" type="button" role="tab" aria-controls="clients" aria-selected="true">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                                <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                                <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
                              </svg> Client -->
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-list" viewBox="0 0 16 16">
                                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"/>
                                <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8m0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"/>
                              </svg>
                              Summary
                          </a>
                        </li>
                        <li class="nav-item" >
                          <a class="nav-link" id="subscription-tab" (click)="onClickTab('subscriptions')" data-bs-toggle="tab" data-bs-target="#subscription" type="button" role="tab" aria-controls="subscription" aria-selected="false">
                            <i class="now-ui-icons shopping_shop"></i> Subscriptions
                          </a>
                        </li>
                        <li class="nav-item"  >
                          <a class="nav-link" id="user-tab" (click)="onClickTab('users')" data-bs-toggle="tab" data-bs-target="#users" type="button" role="tab" aria-controls="userss" aria-selected="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
                              </svg> Users
                          </a>
                        </li>
                      </ul>
                      <div *ngIf="isUserTab" style="margin-right: 1%;">
                        <input type="text" class="search-input" placeholder="Search" title="Search" (keyup)="applyFilter($event)">
                        <button class="add-button" (click)="AddNewUser(null)" >Add User <span><img
                          src="../../../../../../../assets/images/dashboard/add-new-icon.svg" alt=""></span></button>
                      </div>
                </div>
            </div>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="clients" role="tabpanel" aria-labelledby="client-tab">
                    <form [formGroup]="clientForm">
                        <div class="form-section">
                            <div class="details-section">
                              <div class="row form-row">
                                <div class="col-md-4">
                                  <div class="input-label">Client Name<span style="color: red;">*</span></div>
                                  <input type="text" class="form-input" formControlName="clientName"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('clientName')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('clientName')?.errors?.['required']">*Client name is missing.</div>
                                </div>
                                <div class="col-md-4">
                                  <div class="input-label">Client Address<span style="color: red;">*</span></div>
                                  <input type="text" class="form-input" formControlName="clientAddress"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('clientAddress')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('clientAddress')?.errors?.['required']">*Client address is missing.</div>
                                </div>
                                <div class="col-md-4">
                                  <div class="input-label">Practice Name<span style="color: red;">*</span></div>
                                  <input type="text" class="form-input" formControlName="practiceName"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('practiceName')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('practiceName')?.errors?.['required']">*Practice name is missing.</div>
                                    
                                </div>
                              </div>
                              <div class="row form-row">
                                <div class="col-md-4">
                                  <div class="input-label">Communication Email</div>
                                  <input type="email" class="form-input" formControlName="communicationEmail"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('communicationEmail')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('communicationEmail')?.errors?.['pattern']">*Invalid email.</div>
                                </div>
                              
                                <div class="col-md-4">
                                  <div class="input-label">Practice Address</div>
                                  <input type="text" class="form-input" formControlName="practiceAddress"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('practiceAddress')?.invalid ? 'red' : '#ccc'}">
                                </div>
                                <div class="col-md-4">
                                  <div class="input-label">Country<span style="color: red;">*</span></div>
                                  <select name="" id="" class="form-input select-input" formControlName="country"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('country')?.invalid ? 'red' : '#ccc'}">
                                    <option [value]="country.name" *ngFor="let country of countries;let i= index ">{{country.name}}
                                    </option>
                                  </select>
                                  <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('country')?.errors?.['required']">*Practice state is missing.</div>
                                </div>
                            
                              </div>
                              <div class="row form-row">
                             
                                <div class="col-md-4">
                                  <div class="input-label">Practice State<span style="color: red;">*</span></div>
                                  <ng-container *ngIf="!isUsa">
                                    <input type="text" class="form-input" formControlName="practiceState"
                                      [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('practiceState')?.invalid ? 'red' : '#ccc'}">
                                  </ng-container>
                                  <ng-container *ngIf="isUsa">
                                    <select name="" id="" class="form-input select-input" formControlName="practiceState"
                                      [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('practiceState')?.invalid ? 'red' : '#ccc'}">
                                      <option [value]="state.abbreviation" *ngFor="let state of states;let i= index ">{{state.abbreviation}}
                                    </option>
                                    </select>
                                  </ng-container>
                                  <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('practiceState')?.errors?.['required']">*Practice state is missing.</div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-label">Practice City</div>
                                    <ng-container *ngIf="!isUsa">
                                      <input type="text" class="form-input" formControlName="practiceCity"
                                        [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('practiceCity')?.invalid ? 'red' : '#ccc'}">
                                    </ng-container>
                                    <ng-container *ngIf="isUsa">
                                      <select name="" id="" class="form-input select-input" formControlName="practiceCity"
                                      [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('practiceCity')?.invalid ? 'red' : '#ccc'}">
                                      <option [value]="city.name" *ngFor="let city of cities;let i= index ">{{city.name}}
                                      </option>
                                    </select>
                                    </ng-container>
                                  
                                  </div>
                                <div class="col-md-4">
                                  <div class="input-label">Zip<span style="color: red;">*</span></div>
                                  <input type="text" class="form-input" maxlength="5" mask="00000" formControlName="zip"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('zip')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('zip')?.errors?.['required']">*Zip is missing.</div>
                                </div>
            
                              </div>
                              <div class="row form-row">
            
                                <div class="col-md-4">
                                  <div class="input-label">Parent</div>
                                  <select name="" id="" class="form-input select-input" formControlName="parentId">
                                    <option  *ngIf="!clients.length" disabled> No Data</option>
                                    <ng-container *ngFor="let client of clients;let i= index ">
                                      <option [value]="client.id" *ngIf="client.id != clientId && !client.parent_id">{{client.client_name}} - {{client.client_id}}</option>
                                    </ng-container>
                                    
                                  </select>
                                </div>
                               
                                <div class="col-md-4">
                                  <div class="input-label">Logo</div>
                                  <input style="margin-top: -6px;font-size: 14px;" type="file" title="choose file" class="form-input custom-file-input"  formControlName="photo" accept="image/*" (change)="onChangeLogo($event)">
                                  <div class="img-name" *ngIf="selectedLogo || image"><span>*{{selectedLogo?.name || getImageNameFromUrl(image)}}</span></div>
                                </div>
                                <div class="col-md-2">
                                  <span  class="" *ngIf="image"><img class="logo-img" src="{{imgBaseUrl}}{{image}}" alt=""></span>
                                </div>
            
                              </div>
                              <div class="row form-row ">
                                <h2 class="section-header">Contact Details</h2>
                              </div>
                              <div class="row form-row ">
                                <h2 class="section-sub-heading" style="margin-top: -22px">Primary</h2>
                              </div>
                              
                              <div class="row form-row">
                                <div class="col-md-4">
                                  <div class="input-label">First Name<span style="color: red;">*</span></div>
                                  <input type="text" class="form-input" formControlName="clientFirstName"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('clientFirstName')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('clientFirstName')?.errors?.['required']">*First name is missing.</div>
                                </div>
                                <div class="col-md-4">
                                  <div class="input-label">Last Name<span style="color: red;">*</span></div>
                                  <input type="text" class="form-input" formControlName="clientLastName"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('clientLastName')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('clientLastName')?.errors?.['required']">*Last name is missing.</div>
                                </div>
                                <div class="col-md-4">
                                  <div class="input-label">Phone<span style="color: red;">*</span></div>
                                  <input type="text" class="form-input" formControlName="clientPhoneNumber"
                                  maxlength="14" minlength="0" placeholder="(xxx) xxx-xxxx" mask="(000) 000-0000"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('clientPhoneNumber')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('clientPhoneNumber')?.errors?.['required']">*Phone number is missing.</div>
                                </div>
                              </div>
                              <div class="row form-row">
                                <div class="col-md-4">
                                  <div class="input-label">Email<span style="color: red;">*</span></div>
                                  <input type="text" class="form-input" formControlName="clientEmail"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('clientEmail')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('clientEmail')?.errors?.['pattern']">*Invalid email.</div>
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('clientEmail')?.errors?.['required']">*Email is missing.</div>
                                </div>
                              </div>
                              <div class="row form-row">
                                <h2 class="section-sub-heading">Secondary</h2>
                              </div>
                              <div class="row form-row">
                                <div class="col-md-4">
                                  <div class="input-label">First Name<span style="color: red;">*</span></div>
                                  <input type="text" class="form-input" formControlName="primaryFirstName"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('primaryFirstName')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('primaryFirstName')?.errors?.['required']">*First name is missing.</div>
                                </div>
                                <div class="col-md-4">
                                  <div class="input-label">Last Name<span style="color: red;">*</span></div>
                                  <input type="text" class="form-input" formControlName="primaryLastName"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('primaryLastName')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('primaryLastName')?.errors?.['required']">*Last name is missing.</div>
                                </div>
                                <div class="col-md-4">
                                  <div class="input-label">Phone<span style="color: red;">*</span></div>
                                  <input type="text" class="form-input" formControlName="primaryPhoneNumber"
                                  maxlength="14" minlength="0" placeholder="(xxx) xxx-xxxx" mask="(000) 000-0000"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('primaryPhoneNumber')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('primaryPhoneNumber')?.errors?.['required']">*Phone number is missing.</div>
                                </div>
                              </div>
                              <div class="row form-row">
                                <div class="col-md-4">
                                  <div class="input-label">Email<span style="color: red;">*</span></div>
                                  <input type="text" class="form-input" formControlName="primaryEmail"
                                    [ngStyle]="{'border-color': clientFormSubmitted && clientForm.get('primaryEmail')?.invalid ? 'red' : '#ccc'}">
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('primaryEmail')?.errors?.['pattern']">*Invalid email.</div>
                                    <div style="color:red;font-size: 12px;" *ngIf="clientFormSubmitted && clientForm.get('primaryEmail')?.errors?.['required']">*Email is missing.</div>
                                </div>
                              </div>
            
                            </div>
                          </div>
                        <div class="buttons-section">
                          <button class="cancel-btn" [routerLink]="['/home/dashboard']">Cancel</button>
                          <button class="save-btn" (click)="onSubmitClientForm()">Update</button>
                        </div>
                      </form>
                </div>
                <div class="tab-pane fade" id="subscription" role="tabpanel" aria-labelledby="subscription-tab">
                    <div class="">
                        <div class="">
                            <div class="hr-scroll-table">
                                <table mat-table matSort [dataSource]="subDataSource" #subSort="matSort">
                        
                                    <ng-container matColumnDef="name">
                                      <th class="header name-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span>Product</span>
                                      </th>
                                      <td mat-cell *matCellDef="let row" ><span class="badge bg-warning">{{getProductName(row.name)}}</span></td>
                                    </ng-container>
                                    <ng-container matColumnDef="start_date">
                                        <th class="header name-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                          <span>Start Date</span>
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{row.start_date | date: 'MMM d, y'}}</td>
                                      </ng-container>
                                      <ng-container matColumnDef="end_date">
                                        <th class="header name-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                          <span>End Date</span>
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{row.end_date | date: 'MMM d, y'}}</td>
                                      </ng-container>
                                      <ng-container matColumnDef="fees">
                                        <th class="header name-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                          <span>Monthly Subscription Fees</span>
                                        </th>
                                        <td mat-cell *matCellDef="let row"> {{row.subscription_fees | currency : "$ " }}</td>
                                      </ng-container>
                                    <ng-container matColumnDef="status">
                                      <th class="header amount-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span>Status</span>
                                      </th>
                                      <td mat-cell *matCellDef="let row" > <span [ngClass]="getStateColor(row.status)">{{row.status}}</span></td>
                                    </ng-container>
                                
                                    <tr mat-header-row *matHeaderRowDef="subColumnsToDisplay"></tr>
                                    <tr mat-row class="mat-body" *matRowDef="let request; columns: subColumnsToDisplay"></tr>
                                    <tr class="mat-row" *matNoDataRow style="text-align: center">
                                        <td class="mat-cell no-data-cell"  colspan="5" style="
                                      text-align: left;
                                      background-color: #ffffff !important;
                                      padding-left: 35vw !important;
                                      border-bottom: none !important;
                                    "> No data! </td>
                                    </tr>
                                
                                  </table>
                            </div>
                            <mat-paginator [pageSizeOptions]="[15, 20, 25, 50]" #subPaginator [pageSize]="10" showFirstLastButtons (page)="onChangedPage($event)">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="users" role="tabpanel" aria-labelledby="contact-tab">
                    <div class="">
                        <div class="">
                            <div class="hr-scroll-table">
                                <table mat-table matSort [dataSource]="userDataSource"  #userSort="matSort">
                        
                                    <ng-container matColumnDef="first_name">
                                      <th class="header name-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span>First Name</span>
                                      </th>
                                      <td mat-cell *matCellDef="let row">{{row.first_name}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="last_name">
                                      <th class="header amount-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span>Last Name</span>
                                      </th>
                                      <td mat-cell *matCellDef="let row">{{row.last_name}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="username">
                                        <th class="header amount-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                          <span>Username</span>
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{row.username}}</td>
                                      </ng-container>
                                    <ng-container matColumnDef="email">
                                        <th class="header amount-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                          <span>Email</span>
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{row.email}}</td>
                                      </ng-container>
                                      <ng-container matColumnDef="subscribed_products">
                                        <th class="header amount-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                          <span>Products</span>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <ng-container *ngIf="row.subscribed_products?.length === 1"><span class="badge bg-warning">{{getProductName(row.subscribed_products[0].product_name)}}</span></ng-container>
                                            <ng-container *ngIf="row.subscribed_products.length > 1"><span class="badge bg-warning" *ngFor=" let product of row.subscribed_products">{{product.product_name}},</span></ng-container>
                                        </td>
                                      </ng-container>
                                      <ng-container matColumnDef="phone_number">
                                        <th class="header amount-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                          <span>Phone</span>
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{getPhoneNumber(row.phone_code,row.phone_number)}}</td>
                                      </ng-container>
                                    <ng-container matColumnDef="user_created_at">
                                      <th class="header status-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span>Created Date</span>
                                      </th>
                                      <td mat-cell *matCellDef="let row">{{row.user_created_at | date: 'MMM d, y' }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="user_updated_at">
                                      <th class="header date-column" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span>Updated Date</span>
                                      </th>
                                      <td mat-cell *matCellDef="let row">{{row.user_updated_at | date: 'MMM d, y' }}</td>
                                    </ng-container>
                                    
                                    <!-- <ng-container matColumnDef="status">
                                      <th class="header state-column" mat-header-cell *matHeaderCellDef>
                                        <span>Status</span>
                                      </th>
                                      
                                      <td class="state-cell" mat-cell *matCellDef="let row" style="padding-left: 17px;">
                                        <svg height="14" width="14">
                                          <circle cx="7" cy="7" r="7" [ngClass]="getStateColor(row.status)" />
                                        </svg>
                                      </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="actions">
                                        <th class="header state-column" mat-header-cell *matHeaderCellDef style="width: 6%;">
                                          <span>Actions</span>
                                        </th>
                                        <!-- <td class="state-cell" mat-cell *matCellDef="let request">{{getStatusColor(request.status)}}</td> -->
                                        <td class="state-cell" mat-cell *matCellDef="let row">
                                         <span (click)="onEditClientUser(row)" title="Edit"><img src="../../../../../assets/images/dashboard/action/edit.svg" class="action-icon" alt=""></span>
                                         <span (click)="onDeleteClientUser(row)" title="Delete"><img src="../../../../../assets/images/dashboard/action/delete.svg" class="action-icon" alt=""></span>
        
                                        </td>
                                      </ng-container>
                                
                                    <tr mat-header-row *matHeaderRowDef="usersColumnsToDisplay"></tr>
                                    <tr mat-row class="mat-body" *matRowDef="let request; columns: usersColumnsToDisplay"></tr>
                                    <tr class="mat-row" *matNoDataRow style="text-align: center">
                                        <td class="mat-cell no-data-cell" colspan="7" style="
                                      text-align: left;
                                      background-color: #ffffff !important;
                                      padding-left: 35vw !important;
                                      border-bottom: none !important;
                                    "> No data! </td>
                                    </tr>
                                
                                  </table>
                            </div>
                            <mat-paginator [pageSizeOptions]="[15, 20, 25, 50]" #userPaginator [pageSize]="10" showFirstLastButtons (page)="onChangedPage($event)">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
              </div>
            
        </div>
    </div>
  
</div>



