import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, filter, of, switchMap, take, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  constructor(private authService:AuthService) { }
  
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    // add auth header with jwt if account is logged in and request is to the api url
    // const account = this.accountService.accountValue;
    // const isLoggedIn = account?.token;
    let userToken = null;
    try {
      // userToken = localStorage.getItem('userToken');
      userToken = localStorage.getItem('userToken');
    } catch (error) {
      console.error('No access token found. Please logout and try again.')
    }

    if (userToken) {     
        const isApiUrl = request.url.startsWith(environment?.baseUrl) || request.url.startsWith(environment?.backendUrl);
        if (userToken && isApiUrl) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${userToken}` }
            });
          }
         
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          return this.handleUnauthorizedRequests(request, next);
        }
        return throwError(error);
      })
    );

  }
  private handleUnauthorizedRequests(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.generateToken().pipe(
        switchMap((res:any): Observable<HttpEvent<any>> => {
          this.isRefreshing = false;
          if (res.body.status === 'failed') {
            this.authService.logout();
            return of(new HttpResponse());
          } else {
            let newToken = res.body.accessToken;
            this.refreshTokenSubject.next(newToken);
            return next.handle(this.addToken(request, newToken));
          }
          
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.authService.logout();
          return throwError(error);
        })
      );
    } else {
      // If a refresh is already in progress, wait for the new token and then retry the request
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token:any) => {
          return next.handle(this.addToken(request, token));
        })
      );
    }
  }
  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  
}

