
<div class="main-container ">
  <div class="header-container">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="header-section">
                    <div class="logo-section">
                      <img loading="lazy" src="../../assets/images/website/smile-logo.svg" class="logo-icon" />
                      <!-- <img loading="lazy" src="../../assets/images/website/logo-letters.png" class="logo-letters" /> -->
                    </div>
                    <div class="button-section">
                      <a [routerLink]="['/login']" style="text-decoration: none;"><div class="login">Login</div></a>
                      <div class="register">
                        <a href="#contact-form" style="text-decoration: none;"><div class="register-btn" >Register</div></a>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
    <div class="title-container">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="title-innercontainer">
                        <div class="title-img-section">
                          <div class="title-column">
                            <div class="title-start-btn">
                              <div class="main-title">
                                Is inefficient 
                                <div>
                                     billing stifling your practice?
                                </div>
                              </div>
                              <div class="sub-title">Explore how SmileAI can untangle the way.</div>
                              <a href="#contact-form" style="text-decoration: none;"><div class="start-btn">Get started</div></a>
                            </div>
                          </div>
                          <div class="img-column">
                            <img loading="lazy" src="../../assets/images/website/dashboard.svg" class="dashbord-img" />
                          </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="client-logos-container">
      <div class="client-logos-innercontainer">
        <div class="client-logos">
          <div class="client-logo">
            <img src="../../assets/images/website/riverdale 1.png" loading="lazy">
          </div>
          <div class="client-logo">
            <img src="../../assets/images/website/dhi 1.png" loading="lazy">
          </div>
          <div class="client-logo">
            <img src="../../assets/images/website/chicopee children's dentist.png" loading="lazy">
          </div>
          <div class="client-logo">
            <img src="../../assets/images/website/At-motion.png" loading="lazy">
          </div>
          <div class="client-logo">
            <img src="../../assets/images/website/cog.png" loading="lazy">
          </div>
        </div>
      </div>
    </div> -->
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-2-header">
                    SmileAI provides an effective and powerful way to manage your dental
                    practice cash management and patient billing.
                  </div>
            </div>
        </div>
    </div>
    <div class="section-2-container">
      <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-2-innercontainer">
                    <div class="icon-img-column">
                      <div class="icons-img">
                        <img loading="lazy" src="../../assets/images/website/ai.png" class="section-2-img" />
                        <div class="icons-outer-container">
                          <div class="section-2-icons-container">
                            <div class="section-2-icon">
                              <img loading="lazy" src="../../assets/images/website/streamlined.png" class="section-2-icons" />
                              <div class="section-2-icon-text">Streamlined Workflow</div>
                            </div>
                            <div class="section-2-icon">
                              <img loading="lazy" src="../../assets/images/website/Scalability.png" class="section-2-icons" />
                              <div class="section-2-icon-text">Flexible & Scalable</div>
                            </div>
                            <div class="section-2-icon">
                              <img loading="lazy" src="../../assets/images/website/efficient.png" class="section-2-icons" />
                              <div class="section-2-icon-text">Simple & Efficient</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="section-2-content-column">
                      <div class="section-2-title-content">
                        <div class="section-2-title">
                          <span style="color: rgba(0, 169, 232, 1)">Revolutionize</span>
                          <div>
                             Your Dental Practice
                          </div>
                        </div>
                        <div class="section-2-content">
                          At SmileAI, we understand the challenges you face in managing
                          cash at your dental practice. Our software solutions are
                          designed to streamline your workflow, improve accuracy, and
                          increase your overall productivity. Using our software, you can focus on what you do best:
                          <div class="section-2-subcontent"><span>Bring</span> <span class="icon-word" style="color: #FFCB00;padding: 0 10px;">smiles</span><span>to your patients.</span></div>
                          <!-- <div class="section-2-subcontent" style="display: flex; font-size: 24px;font-weight: 500;">
                            <span>Bring</span>
                            <div style="display: flex; flex-direction: column;text-align:center;">
                              <span style="color: orange; margin:0px 8px;">smiles</span>
                                <img src="../../assets/images/website/smile.png" width="25px"  style="margin-left: 30px;"
                                />
                            </div>
                            <span>to your patients.</span>
                          </div> -->
                        </div>
                        <a href="#contact-form" style="text-decoration: none;"><div class="section-2-btn">Transform my practice</div></a>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
      </div>
    </div>
    <div class="section-3-cashport">
      <img loading="lazy" src="../../assets/images/website/Rectangle 5.png" class="rectangle" />
      <div class="cashport-main-container">
       <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="cashport-inner-container">
                    <div class="left-side-column">
                      <div class="section-3-header">
                        <span style="
                                  font-family: Space Grotesk, -apple-system, Roboto, Helvetica,
                                    sans-serif;
                                  font-weight: 700;
                                  color: rgba(0, 169, 232, 1);
                                ">
                          CashPort
                        </span>
                        <span style="
                                  font-family: Space Grotesk, -apple-system, Roboto, Helvetica,
                                    sans-serif;
                                  color: rgba(0, 0, 0, 1);
                                ">
                          - Cash posting process made easy
                        </span>
                      </div>
                      <a  href="#contact-form" style="text-decoration: none;"><div class="section-3-btn">Get started</div></a>
                      <!-- <div class="section-3-btn">Get started</div> -->
                    </div>
                    <div class="right-section-column">
                      <div class="section-3-content">
                        <div>
                          Revolutionize your cash posting process and transform the way
                          you manage your financial transactions leveraging cutting-edge
                          technology and advanced algorithms to automate the cash
                          posting.
                        </div>
                        <br />
                        <div>
                           By harnessing the power of AI and Digital automation, our
                          solution eliminates the need for manual data entry, reduces
                          human error, and significantly improves efficiency through
                          streamlined operations.
                        </div>
          
                      </div>
                    </div>
                  </div>
            </div>
        </div>
       </div>
      </div>
      <div class="feature-block">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="features-container">
                        <div class="feature">
                          <div class="feature-group">
                            <div class="feature-heading"> Increased Accuracy </div>
                            <div class="feature-icon"><img src="../../assets/images/website/inceased-accuracy.png" alt=""></div>
                          </div>
                          <div class="feature-content">
                            Cashport is designed by experts to provide precise and error-free cash posting. Our advanced algorithms
                            automatically match incoming payments to the appropriate accounts.
                          </div>
                        </div>
                        <div class="feature">
                          <div class="feature-group">
                            <div class="feature-heading">Superior Time Saver</div>
                            <div class="feature-icon"><img src="../../assets/images/website/time-saver.png" alt=""></div>
                          </div>
                          <div class="feature-content">
                            Intelligent Cashport solution frees up your team's significant time, enabling them to focus on more
                            strategic and high-value activities, such as customer service and financial analysis.
                          </div>
                        </div>
                        <div class="feature">
                          <div class="feature-group">
                            <div class="feature-heading">Seamless Integration</div>
                            <div class="feature-icon"><img src="../../assets/images/website/seamless-itegration.png" alt=""></div>
                          </div>
                          <div class="feature-content">
                            Our solution is adaptable and compatible with your PM systems and most payor electronic EOBs bringing wide
                            coverage and seamless integration.
                          </div>
                        </div>
                        <div class="feature">
                          <div class="feature-group">
                            <div class="feature-heading">Real-Time Insights</div>
                            <div class="feature-icon"><img src="../../assets/images/website/realtime-insights.png" alt=""></div>
                          </div>
                          <div class="feature-content">
                            Our easy to interpret reports gain valuable insights into your cash flow and payment trends with
                            comprehensive real time reporting to enhance your financial performance.
                          </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="section-4-about">
      <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="about-container">
                    <div class="section-4-about-header">
                      <span style="
                                font-family: Space Grotesk, -apple-system, Roboto, Helvetica,
                                  sans-serif;
                              ">
                        Built for Dental Practices, by
                      </span>
                      <span style="
                                font-family: Space Grotesk, -apple-system, Roboto, Helvetica,
                                  sans-serif;
                                color: rgba(0, 169, 232, 1);
                              ">
                        Dental Professionals
                      </span>
                    </div>
                    <div class="saparator-line"></div>
                    <div class="section-4-content-logos">
                      <div class="section-4-about-content">
                        Our pragmatic dental practice software solutions are designed by
                        dental professionals for dental practices. We understand the
                        unique needs of dental practices and have developed our software
                        to meet those needs.
                      </div>
                      <div class="section-4-icons-container">
                        <div class="icons-text-combo" style="width: 74%;">
                          <div><img loading="lazy" src="../../assets/images/website/all-in-one.png" class="section-4-icons" /></div>
                          <div class="section-4-icon-text">
                            All-In-One
                            <br />
                            ToolKit
                          </div>
                        </div>
                        <div class="icons-text-combo" style="width: 100%;">
                          <div> <img loading="lazy" src="../../assets/images/website/eob.png" class="section-4-icons" /></div>
                          <div class="section-4-icon-text">Integrated Payor EOB Updates</div>
                        </div>
                        <div class="icons-text-combo" style="width: 29%;">
                          <div>
                            <img loading="lazy" src="../../assets/images/website/total-cash.png" class="section-4-icons" />
                          </div>
                          <div class="section-4-icon-text">
                            Total Cash
                            <br />
                            Management
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
      </div>

       <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div #carouselExampleSlidesOnly class="slide carousel section-5">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="slider">
                                <div class="section-5-left-col">
                                    <div class="left-section-container">
                                        <div class="section-5-title">
                                            What our satisfied
                                            <span style="color: rgba(0, 169, 232, 1)">clients</span>
                                            say
                                          </div>
                                          <img
                                          loading="lazy"
                                          src="../../assets/images/website/testmonial-1.png"
                                          class="testmonial-img"
                                        />
                                      <div><img class="ellipse-img" src="../../assets/images/website/ellipse.png" alt=""></div>
                                      <!-- <div class="arrow-icons">
                                        <img
                                          loading="lazy"
                                          (click)="prevSlide()"
                                          src="../../assets/images/website/arrow-left.png"
                                          class="left-arrow"
                                        />
                                        <img
                                          loading="lazy"
                                          (click)="nextSlide()"
                                          src="../../assets/images/website/arrow-right.png"
                                          class="right-arrow"
                                        />
                                      </div> -->
                                    </div>
                                  </div>
                                  <div class="section-5-right-col">
                                    <div class="right-section-container">
                                      <div class="testmonial-content-inner-container">
                                        <div class="testmonial-content">
                                          “ I have been using SmileAI's CashPort software for over a
                                          year now, and it has completely transformed the way I
                                          manage my cash posting. I highly recommend it to any
                                          dental professional looking to streamline their workflow
                                          and improve patient care.”
                                        </div>
                                        <!-- <div class="testmonial-by">Dr. Erin</div>
                                        <div class="testmonial-org">Childrens Dentistry</div> -->
                                      </div>
                                    </div>
                                  </div>
                            </div>
                      </div>
                      <!-- <div class=" carousel-item">
                        <div class="slider">
                            <div class="section-5-left-col">
                                <div class="left-section-container">
                                  <div class="section-5-title">
                                      What our satisfied
                                      <span style="color: rgba(0, 169, 232, 1)">clients</span>
                                      say
                                    </div>
                                    <img
                                    loading="lazy"
                                    src="../../assets/images/website/testmonial-1.png"
                                    class="testmonial-img"
                                  />
                                  <img class="ellipse-img" src="../../assets/images/website/ellipse.png" alt="">
                                  <div class="arrow-icons">
                                      <img
                                        loading="lazy"
                                        (click)="prevSlide()"
                                        src="../../assets/images/website/arrow-left.png"
                                        class="left-arrow"
                                      />
                                      <img
                                        loading="lazy"
                                        (click)="nextSlide()"
                                        src="../../assets/images/website/arrow-right.png"
                                        class="right-arrow"
                                      />
                                    </div>
                                </div>
                              </div>
                              <div class="section-5-right-col">
                                <div class="right-section-container">
                                  <div class="testmonial-content-inner-container">
                                    <div class="testmonial-content">
                                      “ slide 2 I have been using SmileAI's CashPort software for over a
                                      year now, and it has completely transformed the way I
                                      manage my cash posting. I highly recommend it to any
                                      dental professional looking to streamline their workflow
                                      and improve patient care.”
                                    </div>
                                    <div class="testmonial-by">Dr. Erin</div>
                                    <div class="testmonial-org">Childrens Dentistry</div>
                                  </div>
                                </div>
                              </div>
                        </div>
                      </div> -->
                      <!-- <div class=" carousel-item">
                        <div class="slider">
                            <div class="section-5-left-col">
                                <div class="left-section-container">
                                  <div class="section-5-title">
                                      What our satisfied
                                      <span style="color: rgba(0, 169, 232, 1)">clients</span>
                                      say
                                    </div>
                                    <img
                                    loading="lazy"
                                    src="../../assets/images/website/testmonial-1.png"
                                    class="testmonial-img"
                                  />
                                  <img class="ellipse-img" src="../../assets/images/website/ellipse.png" alt="">
                                  <div class="arrow-icons">
                                      <img
                                        loading="lazy"
                                        (click)="prevSlide()"
                                        src="../../assets/images/website/arrow-left.png"
                                        class="left-arrow"
                                      />
                                      <img
                                        loading="lazy"
                                        (click)="nextSlide()"
                                        src="../../assets/images/website/arrow-right.png"
                                        class="right-arrow"
                                      />
                                    </div>
                                </div>
                              </div>
                              <div class="section-5-right-col">
                                <div class="right-section-container">
                                  <div class="testmonial-content-inner-container">
                                    <div class="testmonial-content">
                                      “ slide 3 I have been using SmileAI's CashPort software for over a
                                      year now, and it has completely transformed the way I
                                      manage my cash posting. I highly recommend it to any
                                      dental professional looking to streamline their workflow
                                      and improve patient care.”
                                    </div>
                                    <div class="testmonial-by">Dr. Erin</div>
                                    <div class="testmonial-org">Childrens Dentistry</div>
                                  </div>
                                </div>
                              </div>
                        </div>
                      </div> -->
                      <!-- <div class="carousel-item">
                        <div class="slider">
                            <div class="section-5-left-col">
                                <div class="left-section-container">
                                  <div class="section-5-title">
                                      What our satisfied
                                      <span style="color: rgba(0, 169, 232, 1)">clients</span>
                                      say
                                    </div>
                                  <img
                                    loading="lazy"
                                    src="../../assets/images/website/testmonial-1.png"
                                    class="testmonial-img"
                                  />
                                  <img class="ellipse-img" src="../../assets/images/website/ellipse.png" alt="">
                                  <div class="arrow-icons">
                                      <img
                                        loading="lazy"
                                        (click)="prevSlide()"
                                        src="../../assets/images/website/arrow-left.png"
                                        class="left-arrow"
                                      />
                                      <img
                                        loading="lazy"
                                        (click)="nextSlide()"
                                        src="../../assets/images/website/arrow-right.png"
                                        class="right-arrow"
                                      />
                                    </div>
                                </div>
                              </div>
                              <div class="section-5-right-col">
                                <div class="right-section-container">
                                  <div class="testmonial-content-inner-container">
                                    <div class="testmonial-content">
                                      “ slide 4 I have been using SmileAI's CashPort software for over a
                                      year now, and it has completely transformed the way I
                                      manage my cash posting. I highly recommend it to any
                                      dental professional looking to streamline their workflow
                                      and improve patient care.”
                                    </div>
                                    <div class="testmonial-by">Dr. Erin</div>
                                    <div class="testmonial-org">Childrens Dentistry</div>
                                  </div>
                                </div>
                              </div>
                        </div>
                      </div> -->
                      </div>
                      <!-- <div class="arrow-icons-outer" style="display: none;">
                        <img
                          loading="lazy"
                          (click)="prevSlide()"
                          src="../../assets/images/website/arrow-left.png"
                          class="left-arrow"
                        />
                        <img
                          loading="lazy"
                          (click)="nextSlide()"
                          src="../../assets/images/website/arrow-right.png"
                          class="right-arrow"
                        />
                      </div> -->
                   </div>
            </div>
        </div>
       </div>
      <!-- <div class="slide carousel section-5"  > -->
        <!-- <div class="carousel-inner">
            <div class="slider">
                <div class="section-5-left-col">
                  <div class="left-section-container">
                      <div class="section-5-title">
                          What our satisfied
                          <span style="color: rgba(0, 169, 232, 1)">clients</span>
                          say
                        </div>
                        <img
                        loading="lazy"
                        src="../../assets/images/website/testmonial-1.png"
                        class="testmonial-img"
                      />
                    <img class="ellipse-img" src="../../assets/images/website/ellipse.png" alt="">
                    <div class="arrow-icons">
                      <img
                        loading="lazy"
                        (click)="prevSlide()"
                        src="../../assets/images/website/arrow-left.png"
                        class="left-arrow"
                      />
                      <img
                        loading="lazy"
                        (click)="nextSlide()"
                        src="../../assets/images/website/arrow-right.png"
                        class="right-arrow"
                      />
                    </div>
                  </div>
                </div>
                <div class="section-5-right-col">
                  <div class="right-section-container">
                    <div class="testmonial-content-inner-container">
                      <div class="testmonial-content">
                        “ slide 1 I have been using SmileAI's CashPort software for over a
                        year now, and it has completely transformed the way I
                        manage my cash posting. I highly recommend it to any
                        dental professional looking to streamline their workflow
                        and improve patient care.”
                      </div>
                      <div class="testmonial-by">Dr. Erin</div>
                      <div class="testmonial-org">Childrens Dentistry</div>
                    </div>
                  </div>
                </div>
          </div>
          <div class="slider">
              <div class="section-5-left-col">
                <div class="left-section-container">
                  <div class="section-5-title">
                      What our satisfied
                      <span style="color: rgba(0, 169, 232, 1)">clients</span>
                      say
                    </div>
                    <img
                    loading="lazy"
                    src="../../assets/images/website/testmonial-1.png"
                    class="testmonial-img"
                  />
                  <img class="ellipse-img" src="../../assets/images/website/ellipse.png" alt="">
                  <div class="arrow-icons">
                      <img
                        loading="lazy"
                        (click)="prevSlide()"
                        src="../../assets/images/website/arrow-left.png"
                        class="left-arrow"
                      />
                      <img
                        loading="lazy"
                        (click)="nextSlide()"
                        src="../../assets/images/website/arrow-right.png"
                        class="right-arrow"
                      />
                    </div>
                </div>
              </div>
              <div class="section-5-right-col">
                <div class="right-section-container">
                  <div class="testmonial-content-inner-container">
                    <div class="testmonial-content">
                      “ slide 2 I have been using SmileAI's CashPort software for over a
                      year now, and it has completely transformed the way I
                      manage my cash posting. I highly recommend it to any
                      dental professional looking to streamline their workflow
                      and improve patient care.”
                    </div>
                    <div class="testmonial-by">Dr. Erin</div>
                    <div class="testmonial-org">Childrens Dentistry</div>
                  </div>
                </div>
              </div>
          </div>
          <div class="slider ">
              <div class="section-5-left-col">
                <div class="left-section-container">
                  <div class="section-5-title">
                      What our satisfied
                      <span style="color: rgba(0, 169, 232, 1)">clients</span>
                      say
                    </div>
                    <img
                    loading="lazy"
                    src="../../assets/images/website/testmonial-1.png"
                    class="testmonial-img"
                  />
                  <img class="ellipse-img" src="../../assets/images/website/ellipse.png" alt="">
                  <div class="arrow-icons">
                      <img
                        loading="lazy"
                        (click)="prevSlide()"
                        src="../../assets/images/website/arrow-left.png"
                        class="left-arrow"
                      />
                      <img
                        loading="lazy"
                        (click)="nextSlide()"
                        src="../../assets/images/website/arrow-right.png"
                        class="right-arrow"
                      />
                    </div>
                </div>
              </div>
              <div class="section-5-right-col">
                <div class="right-section-container">
                  <div class="testmonial-content-inner-container">
                    <div class="testmonial-content">
                      “ slide 3 I have been using SmileAI's CashPort software for over a
                      year now, and it has completely transformed the way I
                      manage my cash posting. I highly recommend it to any
                      dental professional looking to streamline their workflow
                      and improve patient care.”
                    </div>
                    <div class="testmonial-by">Dr. Erin</div>
                    <div class="testmonial-org">Childrens Dentistry</div>
                  </div>
                </div>
              </div>
          </div>
          <div class="slider ">
              <div class="section-5-left-col">
                <div class="left-section-container">
                  <div class="section-5-title">
                      What our satisfied
                      <span style="color: rgba(0, 169, 232, 1)">clients</span>
                      say
                    </div>
                  <img
                    loading="lazy"
                    src="../../assets/images/website/testmonial-1.png"
                    class="testmonial-img"
                  />
                  <img class="ellipse-img" src="../../assets/images/website/ellipse.png" alt="">
                  <div class="arrow-icons">
                      <img
                        loading="lazy"
                        (click)="prevSlide()"
                        src="../../assets/images/website/arrow-left.png"
                        class="left-arrow"
                      />
                      <img
                        loading="lazy"
                        (click)="nextSlide()"
                        src="../../assets/images/website/arrow-right.png"
                        class="right-arrow"
                      />
                    </div>
                </div>
              </div>
              <div class="section-5-right-col">
                <div class="right-section-container">
                  <div class="testmonial-content-inner-container">
                    <div class="testmonial-content">
                      “ slide 4 I have been using SmileAI's CashPort software for over a
                      year now, and it has completely transformed the way I
                      manage my cash posting. I highly recommend it to any
                      dental professional looking to streamline their workflow
                      and improve patient care.”
                    </div>
                    <div class="testmonial-by">Dr. Erin</div>
                    <div class="testmonial-org">Childrens Dentistry</div>
                  </div>
                </div>
              </div>
          </div>
        </div> -->
        <!-- <div class="arrow-icons-outer" style="display: none;">
            <img
              loading="lazy"
              (click)="prevSlide()"
              src="../../assets/images/website/arrow-left.png"
              class="left-arrow"
            />
            <img
              loading="lazy"
              (click)="nextSlide()"
              src="../../assets/images/website/arrow-right.png"
              class="right-arrow"
            />
          </div> -->
      <!-- </div> -->
      <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="form-container" id="contact-form">
                    <div class="form-header">
                      Let us set up a conversation <br>  to transform your
                      <span style="color: rgba(0, 169, 232, 1)">Billing Process.</span>
                    </div>
                    <div class="form">
                      <form  [formGroup]="contactForm">
                        <div class="row group">
                          <div class="col-md-5 input-form">
                            <div class="input-label">Email <span style="font-family: Prompt, sans-serif;
                                                color: rgba(235, 69, 69, 1);">*</span></div>
                            <input type="text" class="input-item"  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" formControlName="email" [ngStyle]="{'border-bottom-color': submitted && contactForm.get('email')?.invalid ? 'red' : '#ccc'}">
                            <div style="color:red;padding-top: 1%;" *ngIf="submitted && contactForm.get('email')?.errors?.['required']">Email address is missing.</div>
                            <div style="color:red;padding-top: 1%;" *ngIf="submitted && contactForm.get('email')?.errors?.['pattern']">Please correct the Email address.</div>
                          </div>
                          <div class="col-md-1"></div>
                          <div class="col-md-5 input-form">
                            <div class="input-label">Contact Number <span style="font-family: Prompt, sans-serif;
                                color: rgba(235, 69, 69, 1);">*</span></div>
                            <input type="text" class="input-item" formControlName="phone"  maxlength="14" minlength="0" placeholder="(xxx) xxx-xxxx" mask="(000) 000-0000" [ngStyle]="{'border-bottom-color': submitted && contactForm.get('phone')?.invalid ? 'red' : '#ccc'}">
                            <div style="color:red;padding-top: 1%;" *ngIf="submitted && contactForm.get('phone')?.errors?.['required']">Contact number is missing.</div>
                            <div style="color:red;padding-top: 1%;" *ngIf="submitted && contactForm.get('phone')?.errors?.['pattern']">Please correct the Contact number.</div>
                          </div>
                        </div>
                        <div class="row group">
                          <div class="col-md-5 input-form">
                            <div class="input-label">Contact Name <span style="font-family: Prompt, sans-serif;
                                color: rgba(235, 69, 69, 1);">*</span></div>
                            <input type="text" class="input-item" formControlName="name" [ngStyle]="{'border-bottom-color': submitted && contactForm.get('name')?.invalid ? 'red' : '#ccc'}">
                            <div style="color:red;padding-top: 1%;" *ngIf="submitted && contactForm.get('name')?.errors?.['required']">Contact name is missing.</div>
                            <div style="color:red;padding-top: 1%;" *ngIf="submitted && contactForm.get('name')?.errors?.['pattern']">Please correct the Contact name.</div>
                          </div>
                          <div class="col-md-1"></div>
                          <div class="col-md-5 input-form">
                            <div class="input-label">Practice</div>
                            <input type="text" class="input-item" formControlName="practiceName">
                          </div>
                        </div>
                        <div class="row group">
                          <div class="col-md-5 input-form">
                            <div class="input-label">What is your practice management system?</div>
                            <input type="text" class="input-item" formControlName="practiceManagementSystem">
                          </div>
                          <div class="col-md-1"></div>
                          <div class="col-md-5 input-form">
                            <div class="input-label">Your practice Location</div>
                            <input type="text" class="input-item" formControlName="practiceLocation">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12" style="display: flex;justify-content: center;">
                            <div class="form-submit-btn" (click)="onSubmit()" [attr.disabled]="submitted ? true : null">Submit</div>
                          </div>
                        </div>
                        <div class="spinnerloader-website "  *ngIf="submitted && this.contactForm.valid">
                          <mat-spinner style="left: 45%;"></mat-spinner>
                        </div>
                      </form>
                    </div>
                  </div>
            </div>
        </div>
      </div>
    </div>
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="footer-container">
                        <div class="footer-header">
                          Sign up to get the latest news on our dental practice software
                          solutions.
                        </div>
                        <div class="wrapper">
                          <input type="text" placeholder="Your Email">
                          <button>SUBSCRIBE</button>
                        </div>
                        <div class="footer-logo-section">
                          <img loading="lazy" src="../../assets/images/website/smile-logo.svg" class="footer-logo" />
                          <!-- <img loading="lazy" src="../../assets/images/website/logo-letters.png" class="footer-logo" /> -->
                        </div>
                        <div class="footer-text">
                          Our pragmatic software solutions are designed by dental professionals <br> exclusively for dental practices.
                        </div>
                        <div class="row footer-privacy-social-media">
                          <div class="col-sm-4  project-version">© SmileAI 2024.</div>
                          <div class="col-sm-4 footer-social-icons" style="text-align: center;">
                            <img loading="lazy" src="../../assets/images/website/facebook.svg" class="footer-social-icon" />
                            <img loading="lazy" src="../../assets/images/website/linkdin.svg" class="footer-social-icon" />
                            <img loading="lazy" src="../../assets/images/website/twitter.svg" class="footer-social-icon" />
                            <img loading="lazy" src="../../assets/images/website/instagram.svg" class="footer-social-icon" />
                          </div>
                          <div class="col-sm-4 privacy-policy">Privacy Policy | Terms & Conditions</div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>


