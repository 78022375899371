import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ChartComponent,
} from "ng-apexcharts";

export type ChartOptions = {
  series: any;
  chart: any;
  dataLabels: any;
  plotOptions: any;
  xaxis: any;
  colors:any
};

@Component({
  selector: 'app-bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: ['./bar-graph.component.css']
})
export class BarGraphComponent implements OnInit {
  @Input() inputData: any;
  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;
  colors:string[] = ['#e9724d','#d6d727','#92cad1','#79ccb3'];

  constructor() {
   
  }

  ngOnInit(): void {
    let keys = Object.keys(this.inputData);
    let values = Object.values(this.inputData);
    // let series = this.generateSeriesObject(values);
    this.chartOptions = {
      series: [
          {
            data:[...values]
          }
      ],
      chart: {
        type: "bar",
        height: 235,
      },
      plotOptions: {
        bar: {
          horizontal: true
        },
      },
      
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [
         ...keys
        ]
      },
      
    };
    
  }

  generateSeriesObject(values:any[]) {
    let series:any = []
    values.forEach((value,index) => {
        series.push({
          data: [value],
          color: this.colors[index]
        })
    })
    return series;
  }
  
}
