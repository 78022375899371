import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-scheduler-summary',
  templateUrl: './scheduler-summary.component.html',
  styleUrls: ['./scheduler-summary.component.css']
})
export class SchedulerSummaryComponent implements OnInit {
  @Input() inputData: any;
  dataSource: any;
  columnsToDisplay = ['cheque_no','run_id','payor_name','schedule_status','execution_start','execution_end']
  @ViewChild(MatSort) sort!: MatSort;


  constructor(
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.inputData);
    this.dataSource.sort = this.sort;
  }
  getStatus(status:string) {
    if(status === 'InProgress'){
      return 'In Progress'
    }
    if(status === 'ReadyForExecution') {
      return 'Ready For Execution'
    }
    return status;
  }

}
