import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handling-service/error-handler.service';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  backendUrl = environment.backendUrl;
  websiteUrl = this.backendUrl + 'website/';
  clientUrl = this.backendUrl + 'clients/';
  private profilePictureUrlSubject!: BehaviorSubject<string>;
  public profilePictureUrl$!: Observable<string>;
  user_Data: any;
  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { 
    let userObj:any = localStorage.getItem('user_Data')
    this.user_Data = JSON.parse(userObj);
    this.profilePictureUrlSubject = new BehaviorSubject<string>(this.user_Data.practice_logo);
    this.profilePictureUrl$ = this.profilePictureUrlSubject.asObservable();
  }

  getCountryList(): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.websiteUrl+ "countryList", { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getAllCountriesOfClients(): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.clientUrl+ "getAllCountriesOfClients", { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getStateList(): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.websiteUrl+ "statesList", { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  getAllCitiesOfState(state:string): Observable < any > {
    const headers = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.get(this.websiteUrl+ "usCityList/" + state, { headers,observe: 'response'})
    .pipe(catchError((err) => this.errorHandler.handleError(err)),
      tap(resData => {}));
  }
  isAdmin() {
   
    if(this.user_Data.user_role == 2) {
      return false;
    }
    return true;
  }
  getUserProfilePic() {
     return this.user_Data.practice_logo;
  }
  getESTdate(date:string) {
    return date ? moment(date).tz('America/New_York').format('MM/DD/YYYY'): ''
   }
   updateProfilePictureUrl(newUrl: string): void {
    this.user_Data.practice_logo = newUrl;
    localStorage.setItem('user_Data',JSON.stringify(this.user_Data));
    this.profilePictureUrlSubject.next(newUrl);
  }
  sortData(data:any[],key:string) {
    data.sort((a, b) => {
      const nameA = a[key].toLowerCase();
      const nameB = b[key].toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
  });
  return data;
  }
}
