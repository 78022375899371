
<div class="main-container login-container">
    <div class="container h-100">
        <div class="row  h-100" >
            <div class="col-lg-7">
                <div class="left-section-container">
                    <div class="logo-section">
                        <img loading="lazy" src="../../../assets/images/dashboard/smileai-blue.svg" class="logo" />
                    </div>
                    <div class="title-section">
                        <div class="first-section">CashPort</div>
                        <div class="second-section">Workspace</div>
                    </div>
                    <div class="sub-title-section">
                        <div class="sub-title">Lorem ipsum dolor sit amet, consectetur <br> adipiscing elit,</div>
                    </div>
                    <div class="img-section">
                        <img loading="lazy" src="../../../assets/images/dashboard/login-dashboard.svg" class="dashboard-img" />
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="right-section">
                    <div class="signin-container w-100">
                        <div >
                            <div >
                                    <div class="signin-innercontainer">
                                        <div class="signin-title">
                                            <div>Sign In</div>
                                        </div>
                                        <div class="signin-sub-title">
                                            If you don’t have an account register <br> You can <span style="color: 
                                            #126FF0;font-weight: 500;cursor: pointer;" (click)="onClickRegister()">Register Here !</span>
                                        </div>
                                        <form [formGroup]="loginForm">
                                            <div class="form-container">
                                                <div class="input-label-group" style="margin-bottom: 22px;">
                                                    <div class="input-label">
                                                        Email or Username
                                                    </div>
                                                    <div class="input-field">
                                                        <input type="text" placeholder="Enter Your Email or Username" class="form-input" formControlName="username" [ngStyle]="{'border-bottom-color': submitted && loginForm.get('username')?.invalid ? 'red' : '#ccc'}">
                                                    </div>
                                                </div>
                                                <div class="input-label-group">
                                                    <div class="input-label">
                                                        Password
                                                    </div>
                                                    <div class="input-field">
                                                        <input type="password" placeholder="Enter Your Password" class="form-input" formControlName="password" id="password" [ngStyle]="{'border-bottom-color': submitted && loginForm.get('password')?.invalid ? 'red' : '#ccc'}">
                                                        <img src="../../../assets/images/dashboard/eye.svg" class="eye-icon" alt="" (click)="togglePassword()">
                                                    </div>
                                                </div>
                                                <div class="terms-condition">
                                                    <div class="terms">
                                                        <!-- <input type="checkbox" class="terms-checkbox"> -->
                                                        <!-- <span class="terms-text">I accept terms & condition</span> -->
                                                    </div>
                                                    <div class="forgot-password">
                                                        <div class="terms-text" [routerLink]="['/forgot-password']">Forgot Password ?</div>
                                                    </div>
                                                </div>
                                                <div class="submit-btn">
                                                    <button (click)="onSubmit()" [disabled]="isDisabled">LOG IN</button>
                                                </div>
        
                                            </div>
                                        </form>
                                        <div class="signup-section">
                                            <!-- <div class="signup-text" style="cursor: pointer;" [routerLink]="['/signup']">No Account yet? <span style="color: #212121;font-weight: 700;">SIGN UP</span></div> -->
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>