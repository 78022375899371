import { Injectable } from '@angular/core';
import { throwError, Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

 errors: string='';
 errorCodes$!: Observable<any>;

constructor(private toastr: ToastrService) { }

  handleError(errorRes: HttpErrorResponse): Observable<never> {
    console.log('errorRes', errorRes);
    const errorMessages: string[] = [];
    errorMessages.push('Unknown Error');
    const error = errorRes.error;
    return throwError(errorMessages);
  }

  showSuccessMessage(message:any): void {
    this.toastr.success(message, 'Success', {
      closeButton: true,
      progressBar: true,
      enableHtml: true,
      positionClass:'toast-position'
      // positionClass: 'toast-top-full-width'
      // positionClass: 'toast-bottom-full-width'
    });
  }

  showInfoMessage(message:any): void {
    this.toastr.info(message, 'Login Error', {
      closeButton: true,
      progressBar: true,
      enableHtml: true,
      positionClass: 'toast-position'
      // positionClass: 'toast-bottom-full-width'
    });
  }


  showError(datas:any): void { 
    this.errors = '';
    if (datas) {

        datas.forEach((element:any) => {
          this.errors += '- ' + element + '<br/>';
        });

        this.toastr.error(this.errors, 'Error', {
        closeButton: true,
        progressBar: true,
        enableHtml: true,
        positionClass:'toast-position'
        // positionClass: 'toast-bottom-full-width'
      });
    }
  }
}
