import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.css']
})
export class ConfirmPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmPopupComponent>
  ) { }

  ngOnInit(): void {
    console.log(this.data)
    this.dialogRef.backdropClick().subscribe(_=> {
      this.dialogRef.close();
    })
  }
    cancel(){
        this.dialogRef.close();
    }
    confirm(buttonAction:any){
      this.dialogRef.close(buttonAction);
    }
}
