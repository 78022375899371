import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/Auth-services/auth.service';
import { ErrorHandlerService } from 'src/app/shared/error-handling-service/error-handler.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  submitted: boolean = false;
  isDisabled:boolean= false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  onSubmit() {
    this.submitted = true;
    this.isDisabled = true;
    if (this.loginForm.invalid) {
      this.isDisabled = false;
      return
    }
    let values = { ...this.loginForm.value }
    this.authService.login(values)
      .subscribe
      ((res: any) => {
        console.log('isndde');
        
        this.submitted = false;
        this.isDisabled = false;
        let response = res['body'];
        if (response.status === 'success') {
          this.router.navigate(['home'])
        } else {
          this.errorHandler.showError(Array(response.message));
        }
      },(error) => {
        this.errorHandler.showError(
          Array("Sorry !! Something Went wrong Try again!")
        );
        this.isDisabled = false;
      })
  }
  togglePassword() {
    let passwordField: any = document.getElementById('password');
    if (passwordField.type === "password") {
      passwordField.type = "text";
    } else {
      passwordField.type = "password";
    }
  }
  onClickRegister() {
    this.router.navigate(['/'],{ fragment: 'contact-form' })
  }

}
