import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/Auth-services/auth.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { environment } from 'src/environments/environment';
import { HelperService } from 'src/app/shared/functionality-services/helper.service';

@Component({
  selector: 'app-signout-popup',
  templateUrl: './signout-popup.component.html',
  styleUrls: ['./signout-popup.component.css']
})
export class SignoutPopupComponent implements OnInit {
   user_Data:any;
   imgBaseUrl = environment.baseUrl;
  userImg: any;
  constructor(
    private dialogRef: MatDialogRef<SignoutPopupComponent>,
    private dialog: MatDialog,
    private authService:AuthService,
    public helperService:HelperService
  ) { 
    let userObj:any = localStorage.getItem('user_Data')
    this.user_Data=JSON.parse(userObj);
    // this.helperService.profilePictureUrl$.subscribe(url => {
    //   this.userImg = url;
    // });
    this.userImg = this.user_Data.practice_logo
  }

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(_=> {
      this.dialogRef.close();
    });
  }
  logout() {
    this.dialogRef.close();
    this.authService.logout()
  }
  changePwd(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'forget-password-popup';
    dialogConfig.position = {
      top: '60px',
      right: '20px'
    };
    const dialogRef = this.dialog.open(ChangePasswordComponent, dialogConfig)
  }

}
