<div class="">
    <div class="">
        <div class="hr-scroll-table">
            <table mat-table  [dataSource]="dataSource" style="width: 800px;">
                
                <ng-container matColumnDef="batch_no">
                  <th class="header type-column" mat-header-cell *matHeaderCellDef>
                    <span>Batch No.</span>
                  </th>
                  <td mat-cell *matCellDef="let row">{{row.batch_no}}</td>
                </ng-container>
                
                <ng-container matColumnDef="cheque_no">
                  <th class="header name-column" mat-header-cell *matHeaderCellDef >
                    <span>Cheque No.</span>
                  </th>
                  <td mat-cell *matCellDef="let row">{{row.cheque_no}}</td>
                </ng-container>
                <ng-container matColumnDef="run_id">
                  <th class="header amount-column" mat-header-cell *matHeaderCellDef >
                    <span>Run ID</span>
                  </th>
                  <td mat-cell *matCellDef="let row">{{row.run_id}}</td>
                </ng-container>
                <ng-container matColumnDef="payor_name">
                    <th class="header amount-column" mat-header-cell *matHeaderCellDef >
                      <span>Payor Name</span>
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.payor_name}}</td>
                  </ng-container>
                  <ng-container matColumnDef="schedule_status">
                    <th class="header amount-column" mat-header-cell *matHeaderCellDef >
                      <span>Execution Status</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <span class="badge bg-warning" *ngIf="row.schedule_status === 'InProgress'">{{getStatus(row.schedule_status)}}</span>
                        <span class="badge bg-success" *ngIf="row.schedule_status === 'Completed'">{{getStatus(row.schedule_status)}}</span>
                        <span class="badge bg-primary" *ngIf="row.schedule_status === 'Pending'">{{getStatus(row.schedule_status)}}</span>
                        <span class="badge bg-secondary" *ngIf="row.schedule_status === 'ReadyForExecution'">{{getStatus(row.schedule_status)}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="execution_start">
                    <th class="header amount-column" mat-header-cell *matHeaderCellDef >
                      <span>Run Start Date</span>
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.execution_start | date: 'MMM d, y'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="execution_end">
                    <th class="header amount-column" mat-header-cell *matHeaderCellDef >
                      <span>Run End Date</span>
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.execution_end | date: 'MMM d, y'}}</td>
                  </ng-container>
                  
            
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row class="mat-body" *matRowDef="let request; columns: columnsToDisplay"></tr>
                <tr class="mat-row" *matNoDataRow style="text-align: center">
                    <td class="mat-cell no-data-cell" colspan="7" style="
                  text-align: left;
                  background-color: #ffffff !important;
                  padding-left: 35vw !important;
                  border-bottom: none !important;
                "> No data! </td>
                </tr>
            
              </table>
        </div>
    </div>
</div>
