import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import {
  ChartComponent,
} from "ng-apexcharts";
export type ChartOptions = {
  series: any;
  chart: any;
  xaxis: any;
  dataLabels: any;
  grid: any;
  stroke: any;
  title: any;
  responsive:any
};

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {
  @ViewChild("chart") chart!: ChartComponent;
  @Input() inputData: any;
  public chartOptions!: Partial<ChartOptions>;
  

  constructor() {
    
  }

  ngOnInit(): void {
    let keys:any[] = [];
    let values:any[] = [];
    this.inputData && this.inputData.forEach((obj:any) => {     
      keys.push(moment(obj.schedule_date).format('MM/DD/YYYY'));
      values.push(obj.schedule_count);
    });
    this.chartOptions = {
      series: [
        {
          name: "Schedules",
          data: [...values]
        }
      ],
      chart: {
        height: 310,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: [
          ...keys
        ],
        labels: {
          style: {
            fontSize: '11px', // Specify your desired font size
          },
          rotate: 0 // Set rotate to 0 for straight labels, -90 for vertical labels
        }
      },
      responsive: [
        {
          breakpoint: 4000,
          options: {
            chart: {
              height: 385,
            }
          }
        },
        {
          breakpoint: 1300,
          options: {
            chart: {
              height: 230,
            },
          }
        },
        {
          breakpoint: 1400,
          options: {
            chart: {
              height: 250,
            },
          }
        },
        {
          breakpoint: 1455,
          options: {
            chart: {
              height: 310,
            },
          }
        },
        
  
  
      ]
    };
    
  }

}
