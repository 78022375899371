import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorHandlerService } from 'src/app/shared/error-handling-service/error-handler.service';
import { ClientService } from 'src/app/shared/functionality-services/client.service';

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

@Component({
  selector: 'app-add-user-popup',
  templateUrl: './add-user-popup.component.html',
  styleUrls: ['./add-user-popup.component.css']
})
export class AddUserPopupComponent implements OnInit {
  submitted: boolean = false;
  userForm!: FormGroup;
  products: any[] = []
  clientId: any;
  clientUserId: any;
  clientUserDetails: any;
  isLoading!: boolean;
  title:string = 'Add New User'
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddUserPopupComponent>,
    private clientService: ClientService,
    private errorHandler: ErrorHandlerService) {
    this.clientId = data.clientId;
    if (data.id) {
      this.title = 'Edit User'
      this.clientUserId = data.id,
        this.clientUserDetails = { ...data }
    }

  }

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(_ => {
      this.dialogRef.close();
    });
    this.userForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(emailRegex)]),
      username: new FormControl('',[Validators.minLength(3),Validators.required]),
      password: new FormControl('', [Validators.required,Validators.pattern(passwordRegex)]),
      phoneCode: new FormControl('+1'),
      phoneNumber: new FormControl(''),
      status: new FormControl('active'),
      products: new FormControl('')
    });
    if (this.clientUserId) {
      this.userForm.patchValue(this.clientUserDetails);
      this.userForm.controls['password'].setValidators([
        Validators.pattern(passwordRegex)
      ]);
      this.userForm.get('password')?.updateValueAndValidity();
    }
    this.getAllSubscribedProducts()
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.userForm.value,'userVAlue');
    
    if (this.userForm.invalid) {
      return;
    }
    let data = { ...this.userForm.value };
    data.clientId = this.clientId;
    if (this.clientUserId) {
      data.id = this.clientUserId
      this.updateUser(data);
    } else {
      this.addUser(data);
    }
   
 

  }
  addUser(data:any) {
    this.isLoading = true;
    this.clientService.createNewUser(data)
    .subscribe(
      (res: any) => {
        let response = res.body;
        this.isLoading = false;
        if (response.status === 'success') {
          this.errorHandler.showSuccessMessage(response.message);
          this.dialogRef.close();
        } else {
          this.errorHandler.showError(Array(response.message));
        }
      }, (error) => {
        this.errorHandler.showError(
          Array("Sorry !! Something Went wrong Try again!")
        );
        this.isLoading = false
      }
    )
  }
  updateUser(data:any) {
    this.isLoading = true;
    this.clientService.updateClientUser(data)
    .subscribe(
      (res: any) => {
        this.isLoading = false;
        let response = res.body;
        if (response.status === 'success') {
          this.errorHandler.showSuccessMessage(response.message);
          this.dialogRef.close();
        } else {
          this.errorHandler.showError(Array(response.message));
        }
      }, (error) => {
        this.errorHandler.showError(
          Array("Sorry !! Something Went wrong Try again!")
        );
        this.isLoading = false
      }
    )
  }
  getAllSubscribedProducts() {
    this.clientService.getAllSubscribedProducts(+this.clientId)
      .subscribe
      ((res: any) => {
        let response = res.body;
        if (response.status === 'success') {
          this.products = response.subscriptions;
        } else {
          this.products = [];
        }
      })
  }
  onMaterialGroupChange(event: any) {

  }
  togglePassword() {
    let passwordField: any = document.getElementById('password-field');
    if (passwordField.type === "password") {
      passwordField.type = "text";
    } else {
      passwordField.type = "password";
    }
  }
  cancel() {
    this.dialogRef.close();
  }

}
