<div class="signup-dialog">

    <div mat-dialog-content>  
      <div class="popup-content">
        <div class="avatar-section">
          <div class="avatar">
            <div *ngIf="helperService.isAdmin()">
              <img src="../../../assets/images/dashboard/Star user icon.svg" 
              class="signoutpropic">
            </div>
            <div *ngIf="!helperService.isAdmin()">
              <div  *ngIf="userImg" ><img src="{{imgBaseUrl}}{{userImg}}" class="avatar-icon profile-img signoutpropic" alt=""></div>
              <div  *ngIf="!userImg" >   <img src="../../../assets/images/dashboard/Avatar.svg" 
                class="signoutpropic"></div>
            </div>
          </div>
          <div class="datas">
            <div class="user-name"><span>{{ user_Data['first_name'] + ' ' +user_Data['last_name']}}</span></div>
            <div class="email-text"><span>{{user_Data['email']}}</span></div>
          </div>
        </div>
        <div class="list-section">
          <div class="single-item" (click)="changePwd()">
            <span><img src="../../../assets/images/dashboard/change-pass.svg" alt=""></span>
            <span class="item-text">Change Password</span>
          </div>
          <hr>
          <div class="single-item" (click)="logout()">
            <span><img src="../../../assets/images/dashboard/logout.svg" alt=""></span>
            <span style="color: #0866FF;" class="item-text">Logout</span>
          </div>
        </div>
      </div>
    </div>
  </div>