<div class="right-contents">
    <div *ngIf="isLoading" class="spinnerloader">
      <mat-spinner style="top: 35%; left: 28%"></mat-spinner>
</div>
<div class="outer-container container">
    <div class="">
      <div >
        <div class="row">
            <div class="col-sm-6">
              <!-- <div><img src="../../../../../../../assets/images/dashboard/Misc.svg" alt=""></div> -->
            </div>
            <div class="col-sm-6">
                <div class="top-bar">
                    <div class="navs">
                      <div style="margin-right: 15px;"><img src="../../../../../assets/images/dashboard/info.svg" class="info-icon" alt=""></div> 
                      <!-- <div><img src="../../../../../assets/images/dashboard/Star icon.svg"  class="start-icon" alt=""></div>  -->
                      <div style="margin-right: 3px;" title="Super Admin"><img src="../../../../../assets/images/dashboard/Star user icon.svg" class="start-icon-user" (click)="onClickSignout()" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <section class="cards">
        <div class="car-heading">
            <span style="font-size: 18px;opacity:.6">{{startDate}} - {{endDate}}</span>
        </div>
        <div class="cards-container">
              <div class="single-card">
                  <div class="card-title">New Practices</div>
                  <div class="count-percent-combo">
                      <div class="count">{{clientStatistics?.curMonth}}</div>
                  </div>
              </div>
              <div class="single-card even-card">
                  <div class="card-title">Total Practices</div>
                  <div class="count-percent-combo">
                      <div class="count">{{clientStatistics?.total}}</div>
                  </div>
              </div>
              <div class="single-card ">
                <div class="card-title">Total ARR</div>
                <div class="count-percent-combo">
                    <div class="count"><span>$</span>{{statistics?.totalAnnualRecurringRevenue}}</div>
                </div>
            </div>
       
              <div class="single-card even-card">
                  <div class="card-title">Scheduled Batches</div>
                  <div class="count-percent-combo">
                      <div class="count">{{statistics?.totalSchedules}}</div>
                  </div>
              </div>
              <div class="single-card ">
                  <div class="card-title">Completed Batches</div>
                  <div class="count-percent-combo">
                      <div class="count">{{statistics?.totalCompletedSchedules}}</div>
                  </div>
              </div>
              <div class="single-card even-card ">
                <div class="card-title">Processed Amount</div>
                <div class="count-percent-combo">
                    <div class="count"><span>$</span>{{statistics?.totalProcessedAmount}}</div>
                </div>
            </div>
        </div>
      </section>
      <section class="graphs">
        <div class="row">
            <div class="col-md-6" *ngIf="clientStatusCloumnGraphData">
                <div class="graph-card">
                    <div class="header-row">
                        <div class="header-container">
                            <div class="table-header">Clients</div>
                            <div style="margin-right: -15px;">
                        
                                <input [(ngModel)]="selectYear"  matInput [matDatepicker]="picker" />
                                <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                                <mat-datepicker #picker startView="multi-year" (yearSelected)="chosenYearHandler($event, picker)">
                                </mat-datepicker>
                            </div>
                        </div>
                    </div>
                    <!-- <app-donut-graph  [inputData]="clientDonutGraphData"></app-donut-graph> -->
                    <app-stacked-column-graph [inputData]="clientStatusCloumnGraphData"></app-stacked-column-graph>

                </div>
            </div>
            <div class="col-md-6" *ngIf="schedulerBarChartData">
                <div class="graph-card">
                    <div class="header-row">
                        <div class="header-container">
                            <div class="table-header">Schedules <span style="font-size: 14px;opacity:.6">({{startDate}} - {{endDate}})</span></div>
                        </div>
                    </div>
                    <app-bar-graph [inputData]="schedulerBarChartData"></app-bar-graph>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 2%;">
            <div class="col-md-6" *ngIf="payorPieGraphData">
                <div class="graph-card">
                    <div class="header-row">
                        <div class="header-container">
                            <div class="table-header">Payors</div>
                        </div>
                    </div>
                    <app-pie-chart  [inputData]="payorPieGraphData"></app-pie-chart>
                </div>
            </div>
            <div class="col-md-6" *ngIf="dateWiseSchedule">
                <div class="graph-card">
                    <div class="header-row">
                        <div class="header-container">
                            <div class="table-header">Schedules Last Week</div>
                        </div>
                    </div>
                    <app-line-chart [inputData]="dateWiseSchedule"></app-line-chart>
                </div>
            </div>
        </div>
      </section>
    </div>
  </div>
 
