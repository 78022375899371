import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/Auth-services/auth.service';
import { ErrorHandlerService } from 'src/app/shared/error-handling-service/error-handler.service';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;


export function passwordMatchValidator(controlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.root.get('password');
    const confirmPassword = control.root.get(controlName);

    if (password && confirmPassword && password.value !== confirmPassword.value) {
      return { passwordMismatch: true };
    } else {
      return null;
    }
  };
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {
  formSubmitted:boolean = false;
  emailForm!: FormGroup;
  otpForm!: FormGroup;
  passwordForm!: FormGroup
  passSubmitted: boolean = false
  emailSubmitted: boolean = false;
  otpSubmitted: boolean = false;
  constructor(
    private authService:AuthService,
    private errorHandler:ErrorHandlerService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.emailForm = new FormGroup({
      username: new FormControl('', [Validators.required])
    });
    this.otpForm = new FormGroup({
      otp: new FormControl('', [Validators.required])
    });
    this.passwordForm = new FormGroup({
      password: new FormControl('', [Validators.required,Validators.pattern(passwordRegex)]),
      passwordConfirm: new FormControl('', [Validators.required, passwordMatchValidator('passwordConfirm')])
    });

  }
  showEmailForm() {
    return !this.emailSubmitted;
  }
  showOtpForm() {
    return this.emailSubmitted && !this.otpSubmitted
  }
  showChangePasswordForm() {
    return this.emailSubmitted && this.otpSubmitted
  }
  onSubmitEmail() {
    if (this.emailSubmitted && this.otpSubmitted) {
      if (this.passwordForm.invalid) {
        return;
      }
      this.changePassword()
      return;
      
    }
    if (this.emailSubmitted) {
      if (this.otpForm.invalid) {
        return;
      }
      this.verifyOTP()
      return;
      
    }
    if (this.emailForm.invalid) {
      return;
    }
    this.generateOtp();
    
  }
  generateOtp() {
    this.formSubmitted = true
    let values = { ...this.emailForm.value };
    this.authService.generateOTP(values)
    .subscribe((res:any) => {
      this.formSubmitted = false;
      if(res.body.status === 'success') {
        this.errorHandler.showSuccessMessage(res.body.message);
        this.emailSubmitted = true;
      } else {
        this.errorHandler.showError(Array(res.body.message));
      }
      
    }, (error) => {
      this.errorHandler.showError(
        Array("Sorry !! Something Went wrong Try again!")
      );
      this.formSubmitted = false;
    })
  }
  verifyOTP() {
    this.formSubmitted = true;
    let otpValues = { ...this.otpForm.value,...this.emailForm.value };
    this.authService.verifyOTP(otpValues)
    .subscribe((res:any) => {
      this.formSubmitted = false;
      if(res.body.status === 'success') {
        this.errorHandler.showSuccessMessage(res.body.message);
        this.otpSubmitted = true;
      } else {
        this.errorHandler.showError(Array(res.body.message));
      }
      
    }, (error) => {
      this.errorHandler.showError(
        Array("Sorry !! Something Went wrong Try again!")
      );
      this.formSubmitted = false;
    })
  }
  changePassword() {
    this.formSubmitted = true;
    let passValues = { ...this.emailForm.value, ...this.passwordForm.value};
    this.authService.changePassword(passValues)
    .subscribe((res:any) => {
      this.formSubmitted = false;
      if(res.body.status === 'success') {
        this.errorHandler.showSuccessMessage(res.body.message);
        this.passSubmitted = true;
        this.authService.logout();
      } else {
        this.errorHandler.showError(Array(res.body.message));
      }
      
    }, (error) => {
      this.errorHandler.showError(
        Array("Sorry !! Something Went wrong Try again!")
      );
      this.formSubmitted = false;
    })
  }
  togglePassword() {
    let passwordField: any = document.getElementById('new-password');
    if (passwordField.type === "password") {
      passwordField.type = "text";
    } else {
      passwordField.type = "password";
    }
  }




}
