<div class="main-container login-container">
  <div class="container h-100">
    <div class="row  h-100">
      <div class="col-lg-7">
        <div class="left-section-container">
          <div class="logo-section">
            <img loading="lazy" src="../../../assets/images/dashboard/smileai-blue.svg" class="logo" />
          </div>
          <div class="title-section">
            <div class="first-section">CashPort</div>
            <div class="second-section">Workspace</div>
          </div>
          <div class="sub-title-section">
            <div class="sub-title">Lorem ipsum dolor sit amet, consectetur <br> adipiscing elit,</div>
          </div>
          <div class="img-section">
            <img loading="lazy" src="../../../assets/images/dashboard/login-dashboard.svg" class="dashboard-img" />
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="right-section">
          <div class="signin-container w-100">
            <div>
              <div>
                <div>
                  <div class="signin-innercontainer">
                    <div class="signin-title">
                      <div>Change Password</div>
                    </div>
                    <form [formGroup]="emailForm">
                      <div class="form-container" *ngIf="showEmailForm()">
                        <ng-container>
                          <div class="input-label-group" style="margin-bottom: 22px;">
                            <div class="input-label">
                              Email or Username
                            </div>
                            <div class="input-field">
                              <input type="text" placeholder="Enter Your Email or Username" class="form-input" formControlName="username"
                                [ngStyle]="{'border-bottom-color': !emailSubmitted && emailForm.touched && emailForm.get('username')?.invalid ? 'red' : '#ccc'}">
                            </div>
                          </div>
                        </ng-container>
                        <div class="submit-btn">
                          <button (click)="onSubmitEmail()" [disabled]="formSubmitted">Submit</button>
                          <div class="signup-text" [routerLink]="['/login']">Back to Login?</div>
                        </div>
                      </div>
                    </form>
                    <form [formGroup]="otpForm">
                      <div class="form-container" *ngIf="showOtpForm()">
                        <ng-container>
                          <div class="input-label-group" style="margin-bottom: 22px;">
                            <div class="input-label">
                              OTP
                            </div>
                            <div class="input-field">
                              <input type="text" class="form-input" placeholder="Enter Your OTP" formControlName="otp"
                                [ngStyle]="{'border-bottom-color': emailSubmitted && !otpSubmitted && otpForm.touched && otpForm.get('otp')?.invalid ? 'red' : '#ccc'}">
                            </div>
                          </div>
                        </ng-container>
                        <div class="submit-btn">
                          <button (click)="onSubmitEmail()" [disabled]="formSubmitted">Submit</button>
                          <div class="signup-text" [routerLink]="['/login']">Back to Login?</div>
                        </div>

                      </div>
                    </form>
                    <form [formGroup]="passwordForm">
                      <div class="form-container" *ngIf="showChangePasswordForm()">
                        <ng-container>
                          <div class="input-label-group" style="margin-bottom: 22px;">
                            <div class="input-label">
                              New Password
                            </div>
                            <div class="input-field">
                              <input type="password" id="new-password" placeholder="Enter Your Password" class="form-input" formControlName="password"
                                [ngStyle]="{'border-bottom-color': emailSubmitted && otpSubmitted && passwordForm.touched && passwordForm.get('password')?.invalid ? 'red' : '#ccc'}">
                                <img src="../../../assets/images/dashboard/eye.svg" class="eye-icon" alt="" (click)="togglePassword()">
                            </div>
                            <div style="color:red;font-size: 12px;" *ngIf="emailSubmitted && otpSubmitted && passwordForm.touched &&passwordForm.get('password')?.errors?.['pattern']">*Password must be at least 8 characters long and contain 1 character, 1 capital letter, 1 special character, and 1 number.</div>
                          </div>
                          <div class="input-label-group">
                            <div class="input-label">
                              Repeat Password
                            </div>
                            <div class="input-field">
                              <input type="password" id="repeat-password" placeholder="Enter Your Password" class="form-input" formControlName="passwordConfirm"
                                [ngStyle]="{'border-bottom-color': emailSubmitted && otpSubmitted && passwordForm.touched && passwordForm.get('passwordConfirm')?.invalid ? 'red' : '#ccc'}">
                                <!-- <img src="../../../assets/images/dashboard/eye.svg" class="eye-icon" alt="" (click)="togglePassword()"> -->
                            </div>
                          </div>
                        </ng-container>

                        <div class="submit-btn">
                          <button (click)="onSubmitEmail()" [disabled]="formSubmitted">Submit</button>
                          <div class="signup-text" [routerLink]="['/login']">Back to Login?</div>
                        </div>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
