import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  user_Data: any;
  isAdmin: boolean = true;


  constructor(
  ) { }

  ngOnInit(): void {
    let userObj:any = localStorage.getItem('user_Data');
    this.user_Data = JSON.parse(userObj);
    if(this.user_Data.user_role == 2) {
      this.isAdmin = false;
    }
  }
}
