import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { SignoutPopupComponent } from 'src/app/Smile-AI/signout-popup/signout-popup.component';
import { ErrorHandlerService } from 'src/app/shared/error-handling-service/error-handler.service';
import { DashboardService } from 'src/app/shared/functionality-services/dashboard.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  statistics:any
  isLoading:boolean = false;
  monthlyUserDetails:any;
  schedulerDetails:any;
  clientDetails:any;
  payorDetails:any;
  dateWiseSchedule:any;
  totalUserDetails:any;
  clientStatistics:any;
  totalCompletedSchedules!:number;
  totalSchedules!:number
  schedulerBarChartData:any;
  clientDonutGraphData:any;
  payorPieGraphData:any;
  clientStatusCloumnGraphData:any
  endDate: string;
  startDate: string;
  @ViewChild('picker', { static: false })
  private picker!: MatDatepicker<Date>;  
  selectYear: any;


  constructor(
    private dialog: MatDialog,
    private dashboardService:DashboardService,
    private errorHandler:ErrorHandlerService
  ) { 
    this.endDate = moment(new Date()).format('MM/DD/YYYY');
    let previousWeekDate = new Date(this.endDate);
    previousWeekDate.setDate(previousWeekDate.getDate() - 6);

    // Format the date to MMDDYYYY format
     this.startDate = moment(previousWeekDate).format('MM/DD/YYYY');
     this.selectYear = new Date()

  }

  ngOnInit(): void {
    this.getStatistics();
    this.getClientStatusCount(this.selectYear.getFullYear());
  }

  getStatistics() {
    this.isLoading = true;
    let data = {
      startDate:this.startDate,
      endDate:this.endDate
    }
    this.dashboardService.getDashboardStatisticsForAdmin(data)
    .subscribe(
      (res:any) => {
        this.isLoading = false;
        let response = res.body;
        if(response.status === 'success') {
          this.monthlyUserDetails = response.statistics['monthlyUserDetails'];
          this.schedulerDetails = response.statistics['schedultStatusStatistics'];
          this.totalUserDetails = response.statistics['totalUserDetails'];
          this.clientDetails =  response.statistics['totalClientDetails'];
          this.payorDetails = response.statistics['insuranceStatusStatistics'];
          this.dateWiseSchedule = response.statistics['datewiseSchedule'];
          this.statistics = {};
          this.statistics['totalCompletedSchedules'] = +response.statistics['totalCompletedSchedulesCountAdmin'].total_completed_schedules || 0;
          this.statistics['totalSchedules'] = +response.statistics['totalSchedulesCountAdmin'].total_schedules || 0;
          this.statistics['totalProcessedAmount'] = response.statistics['totalProcessedAmountAdmin'].total_processed_amount || 0;
          this.statistics['totalAnnualRecurringRevenue'] = +response.statistics['totalAnnualRecurringRevenue'] || 0

          this.clientStatistics = {};
          this.clientStatistics['curMonth'] = response.statistics['monthlyClientDetails'].curMonthClientCount || 0;
          this.clientStatistics['total'] = response.statistics['totalClientDetails'].activeClientCount || 0;
          this.schedulerBarChartData = {};
          this.schedulerBarChartData['Ready To Execute'] = this.schedulerDetails['ready_for_execution_count'] || 0;
          this.schedulerBarChartData['Pending'] = this.schedulerDetails['pending_count'] || 0;
          this.schedulerBarChartData['In Progress'] = this.schedulerDetails['in_progress_count'] || 0;
          this.schedulerBarChartData['Completed'] = this.schedulerDetails['completed_count'] || 0;
          this.clientDonutGraphData = {};
          this.clientDonutGraphData['Active'] = +this.clientDetails['activeClientCount'] || 0;
          this.clientDonutGraphData['Inactive'] = +this.clientDetails['inactiveClientCount'] || 0;
          this.payorPieGraphData = {};
          this.payorPieGraphData['Active'] = +this.payorDetails['active_insurance_count'] || 0;
          this.payorPieGraphData['Inactive'] = +this.payorDetails['inactive_insurance_count'] || 0;

        }
      }, (error) => {
        this.errorHandler.showError(
          Array("Sorry !! Something Went wrong Try again!")
        );
        this.isLoading = false
      }
    )
  }

  getClientStatusCount(year:number) {
    this.isLoading = true;
    let data = {
      year: year
    };
    this.dashboardService.getClientStatusCountForAdmin(data)
    .subscribe(
      (res:any) => {
        let response = res.body;
        this.isLoading = false;
        if(response.status === 'success') {
          let labels:string[] = [];
         
          let activeValues:number[] = [];
          let inactiveValues:number[] = [];
          let clientData = response.statistics;
          clientData.forEach((item:any) => {
              labels.push(item.month);
              activeValues.push(+item.cumulative_active_clients);
              inactiveValues.push(+item.inactive_clients)
          });
          let graphData:any = {};
          graphData['labels'] = labels;
          graphData['Active'] = activeValues;
          graphData['Inactive'] = inactiveValues;
          this.clientStatusCloumnGraphData = null;
          this.clientStatusCloumnGraphData = { ...graphData };
        }
        
      }
    )
  }
 
  chosenYearHandler(ev:any, input:any){
    let { _d } = ev;
    this.selectYear = _d;
    this.picker.close();
     let year = new Date(this.selectYear).getFullYear();
     this.getClientStatusCount(year)  
  }
    
  onClickSignout() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'signout-popup';
    dialogConfig.position = {
      top: '60px',
      right: '20px'
    };
    const dialogRef = this.dialog.open(SignoutPopupComponent, dialogConfig)
  }
}
