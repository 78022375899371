<div id="chart">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [title]="chartOptions.title"
      [theme]="chartOptions.theme"
      [responsive]="chartOptions.responsive"
      [colors]="chartOptions?.colors"
    ></apx-chart>
  </div>
