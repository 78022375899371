import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { SignoutPopupComponent } from 'src/app/Smile-AI/signout-popup/signout-popup.component';
import { ErrorHandlerService } from 'src/app/shared/error-handling-service/error-handler.service';
import { DashboardService } from 'src/app/shared/functionality-services/dashboard.service';
import { HelperService } from 'src/app/shared/functionality-services/helper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.css']
})
export class ClientDashboardComponent implements OnInit {
  statistics:any
  isLoading:boolean = false;
  monthlyUserDetails:any;
  schedulerDetails:any;
  totalUserDetails:any;
  schedulerBarChartData:any;
  lastFiveSchedules:any;
  user_Data: any;
  client_id: any;
  payorDetails: any;
  startDate:any;
  endDate:any;
  imgBaseUrl = environment.baseUrl;
  payorPieGraphData: any;
  monthWiseScheduleDetails: any;
  dateWiseSchedule: any;
  totalCompletedSchedules!: number;
  totalSchedules!: number;
  userImg: any;
  constructor(
    private dialog: MatDialog,
    private dashboardService:DashboardService,
    private errorHandler:ErrorHandlerService,
    public helperService:HelperService
  ) { 
    this.endDate = moment(new Date()).format('MM/DD/YYYY');
    let previousWeekDate = new Date(this.endDate);
    previousWeekDate.setDate(previousWeekDate.getDate() - 6);

    // Format the date to MMDDYYYY format
     this.startDate =  moment(previousWeekDate).format('MM/DD/YYYY')
  }

  ngOnInit(): void {
    let userObj:any = localStorage.getItem('user_Data');
    this.user_Data = JSON.parse(userObj);
    // this.helperService.profilePictureUrl$.subscribe(url => {
    //   this.userImg = url;
    // });
    this.userImg = this.user_Data.practice_logo
    this.client_id = this.user_Data.client_id;
    this.getStatistics()
  }

  getStatistics() {
    this.isLoading = true;
    let data = {
      startDate: this.startDate,
      endDate: this.endDate
    }
    this.dashboardService.getDashboardStatisticsForClient(this.client_id,data)
    .subscribe(
      (res:any) => {
        this.isLoading = false;
        let response = res.body;
        if(response.status === 'success') {    
          this.monthlyUserDetails = response.statistics['monthlyUserDetails'];
          this.schedulerDetails = response.statistics['scheduleStatusDetails'];
          this.totalUserDetails = response.statistics['totalUserDetails'];
          this.payorDetails = response.statistics['insuranceStatusDetails'];
          this.lastFiveSchedules = response.statistics['lastFiveScheduleDetails'];
          this.dateWiseSchedule = response.statistics['dateWiseSchedule'];
          this.statistics = {};
          this.statistics['totalCompletedSchedules'] = +response.statistics['totalCompletedSchedulesCount'].total_completed_schedules || 0;
          this.statistics['totalSchedules'] = +response.statistics['totalSchedulesCountClient'].total_schedules || 0;
          this.statistics['totalProcessedAmount'] = response.statistics['totalProcessedAmount'].total_processed_amount || 0;
          this.statistics['totalProcessedTransactions'] = response.statistics['totalProcessedTransactions'].total_processed_amount || 0;
          this.statistics['totalPostedAmount'] = response.statistics['totalPostedAmount'].total_posted_amount || 0;
          this.statistics['totalPostedTransactions'] = response.statistics['totalPostedTransactions'].total_posted_amount || 0;
          this.schedulerBarChartData = {};
          this.schedulerBarChartData['Ready To Execute'] = this.schedulerDetails['ready_for_execution_count'] || 0;
          this.schedulerBarChartData['Pending'] = this.schedulerDetails['pending_count'] || 0;
          this.schedulerBarChartData['In Progress'] = this.schedulerDetails['in_progress_count'] || 0;
          this.schedulerBarChartData['Completed'] = this.schedulerDetails['completed_count'] || 0;
          this.payorPieGraphData = {};
          this.payorPieGraphData['Active'] = +this.payorDetails['active_insurance_count'] || 0;
          this.payorPieGraphData['Inactive'] = +this.payorDetails['inactive_insurance_count'] || 0;
        }
      }, (error) => {
        this.errorHandler.showError(
          Array("Sorry !! Something Went wrong Try again!")
        );
        this.isLoading = false
      }
    )
  }

    
  onClickSignout() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'signout-popup';
    dialogConfig.position = {
      top: '60px',
      right: '20px'
    };
    const dialogRef = this.dialog.open(SignoutPopupComponent, dialogConfig)
  }
  calculateWidth(practiceName: string): number {
    // Calculate the width based on the length of the practice name
    // You can adjust the multiplier as needed based on your design
    const width = ((practiceName?.length || 0) * 10 ) + 140; // Adjust multiplier as needed
    return width;
}
}
